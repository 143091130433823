import * as React from "react";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { useEffect, useState } from "react";
import { TableWrapper, TableWrapperPrizePool } from "./App.styles";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { Typography } from "@mui/material";

const initAward = 30000;
const PrizePoolTable = ({ totalPoints }: { totalPoints: number }) => {
	const [tableData, setTableData] = useState<any[]>(
		new Array(5).fill(<Typography fontSize="0.8rem">Coming soon</Typography>)
	);
	// useEffect(() => {
	// 	const firstRow = `MeAI tokens in $ ${Math.round(
	// 		initAward + totalPoints * 0.001
	// 	)}`;
	// 	let arr = tableData;
	// 	arr[0] = firstRow;
	// 	setTableData(arr);
	// }, [totalPoints]);

	useEffect(() => {
		let arr = tableData;
		arr[0] = (
			<Typography fontSize="0.8rem">
				2 weeks in Bali for 2 people, optional meeting with Dawid and guided
				tour for 1 full day. Flights, accommodation, and breakfast included.
				Available in a lottery system for top 50
			</Typography>
		);
		arr[1] = (
			<Typography fontSize="0.8rem" style={{ display: "inline-block" }}>
				2,500,000 $MEAI tokens initially worth{" "}
				<Typography
					fontSize="1.2rem"
					color={`rgb(21, 253, 234)`}
					style={{ display: "inline-block" }}
				>
					15,000
				</Typography>{" "}
				<Typography
					fontSize="0.9rem"
					color={`rgb(21, 253, 234)`}
					style={{ display: "inline-block" }}
				>
					USD
				</Typography>{" "}
				(vesting rules from Presale 1 apply) available for sharing for all
				participants
			</Typography>
		);
		arr[2] = (
			<Typography fontSize="0.8rem">
				2,604,952 $MEAI tokens initially worth{" "}
				<Typography
					fontSize="1.2rem"
					color={`rgb(21, 253, 234)`}
					style={{ display: "inline-block" }}
				>
					15,630
				</Typography>{" "}
				<Typography
					fontSize="0.9rem"
					color={`rgb(21, 253, 234)`}
					style={{ display: "inline-block" }}
				>
					USD
				</Typography>
				, left from Presale 0 (vesting rules from Presale 1 apply) available for
				sharing for all participants
			</Typography>
		);
		arr[3] = (
			<Typography fontSize="0.8rem">
				Special limited MeAI NFTs for top 100
			</Typography>
		);
		arr[4] = (
			<Typography fontSize="0.8rem">
				Original MeAI t-shirts for top 20 participants with the highest global
				SP gain
			</Typography>
		);

		setTableData(arr);
	}, []);

	return (
		<div>
			<TableWrapperPrizePool>
				<Table
					sx={{ minWidth: 500, maxWidth: 800 }}
					size="small"
					aria-label="a dense table"
				>
					<TableHead>
						<TableRow>
							<TableCell>
								<Typography
									textTransform="uppercase"
									color={`rgb(21, 253, 234)`}
									fontWeight="700"
									fontFamily="Dosis"
									align="center"
									variant="h5"
								>
									Prize Pool
								</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{tableData.map((elem, index) => (
							<TableRow key={`${index}-prizepool`}>
								<TableCell>
									<div>{elem}</div>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableWrapperPrizePool>
		</div>
	);
};

export default PrizePoolTable;
