import { useState } from "react";

import { IconButton, Modal, TextField, Typography } from "@mui/material";
import {
	BoxColumn,
	ContactUsButton,
	CustomBox,
	InputWrapper,
	InputWrapperEmail,
	SendWrapper,
	Textarea
} from "./App.styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { ReactComponent as SendIcon } from "./../src/icons/send.svg";
import { addToSentry } from "./sentryUtils";

const FeedbackModal = ({ setOpen, open, content }: any) => {
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<CustomBox>{content}</CustomBox>
		</Modal>
	);
};

export default FeedbackModal;
