import { Button } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import styled, { css } from "styled-components/macro";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";

export enum Breakpoints {
	XL = "1920px",
	L = "1439px",
	M = "1227px",
	S = "1024px",
	XS = "767px"
}
export const SummaryRow = styled.div`
	justify-content: flex-start !important;
`;

export const MainWrapper = styled.div`
	.MuiPaper-root {
		background-color: rgb(0, 1, 34);
	}
	display: flex;
	margin: 20px;
	flex-direction: column;
	align-items: center;

	justify-content: center;

	@media (max-width: ${Breakpoints.S}) {
		margin: 0;
		display: block;

		table {
			max-width: 100%;
			min-width: 100%;
			margin-bottom: 20px;
		}
	}
`;
export const TableWrapper = styled.div`
	display: flex;
	margin: 20px;
	flex-direction: column;
	align-items: center;

	justify-content: center;

	table {
		background-image: linear-gradient(
			to bottom,
			rgb(21, 253, 234) 0%,
			rgb(102, 129, 235) 33%,
			rgb(248, 6, 242) 100%
		); /* the gradient */
		background-origin: border-box; /* set background to start from border-box */
		border-spacing: 5px; /* space between each cell */
		
	}

	thead {
		border: 9px solid transparent;
	}

	tbody {
		border-top: 6px solid transparent;
	}

	tr {
		border-top: 4px solid transparent;
		border-bottom: 4px solid transparent;
	}

	th {
		border: 4px solid transparent;
		background: rgb(0, 1, 34);
		color: white;
		p {
			display: flex;    
			justify-content: center;
		 align-items: center;
		}
	
	}

	td {
		padding: 0;
		border: 0;

		div:first-of-type {
			background: rgb(0, 1, 34);
			padding: 16px;
			max-height: 25px;
			min-height: 25px;
			color: white;
			display: flex;    
   			justify-content: center;
			align-items: center;
}


		}
	}

	@media (max-width: ${Breakpoints.S}) {
		margin: 0;
		display: block;

		table {
			max-width: 100%;
			min-width: 100%;
			margin-bottom: 20px;
		}
	}
`;

export const RankTableWrapper = styled.div`
	display: flex;
	margin: 0 0 40px 0;
	flex-direction: column;
	align-items: center;

	justify-content: center;

	table {
		background-image: linear-gradient(
			to top,
			rgb(21, 253, 234)  0%,
			rgb(21, 253, 234)  100%
		  );
		background-origin: border-box; /* set background to start from border-box */
		border-spacing: 5px; /* space between each cell */
		border: 4px solid transparent;
		
	}

	thead {
		border: 6px solid transparent;
	}

	tbody {
		border-top: 4px solid transparent;
	}

	tr {
		border-top: 4px solid transparent;
		border-bottom: 4px solid transparent;
	}

	th {
		border: 4px solid transparent;
		background: rgb(0, 1, 34);
		color: white;
		p {
			display: flex;    
			justify-content: center;
		 align-items: center;
		}
	
	}

	td {
		padding: 0;
		border: 0;

		div:first-of-type {
			background: rgb(0, 1, 34);
			padding: 8px;
			max-height: 25px;
			min-height: 25px;
			color: white;
			display: flex;    
   			justify-content: center;
			align-items: center;
}


		}
	}

	@media (max-width: ${Breakpoints.XS}) {
		margin: 40px 0;
		display: block;

		table {

			thead {
				height: 30px;
			} 
			max-width: 100%;
			min-width: 100%;
			padding-bottom: 20px;

		}
	}
`;

export const StatsTableWrapper = styled.div`
	display: flex;
	margin: 40px 20px;
	flex-direction: column;
	align-items: center;

	justify-content: center;

	table {
		background-image: linear-gradient(
			to top,
			rgba(248, 6, 242, 0.8) 0%,
			rgba(248, 6, 242, 0.8) 46.85%,
			rgba(21, 253, 234, 0.8) 46.85%,
			rgba(21, 253, 234, 0.8) 100%
		  );
		background-origin: border-box; /* set background to start from border-box */
		border-spacing: 5px; /* space between each cell */
		border: 4px solid transparent;
		
	}

	thead {
		border: 6px solid transparent;
	}

	tbody {
		border-top: 4px solid transparent;
	}

	tr {
		border-top: 4px solid transparent;
		border-bottom: 4px solid transparent;
	}

	th {
		border: 4px solid transparent;
		background: rgb(0, 1, 34);
		color: white;
		p {
			display: flex;    
			justify-content: center;
		 align-items: center;
		}
	
	}

	td {
		padding: 0;
		border: 0;

		div:first-of-type {
			background: rgb(0, 1, 34);
			padding: 8px;
			max-height: 25px;
			min-height: 25px;
			color: white;
			display: flex;    
   			justify-content: center;
			align-items: center;
}


		}
	}

	@media (max-width: ${Breakpoints.XS}) {
		margin: 40px 0;
		display: block;

		table {

			thead {
				height: 30px;
			} 
			max-width: 100%;
			min-width: 100%;
			padding-bottom: 20px;
	

			background-image: linear-gradient(
				to top,
				rgb(248, 6, 242) 0%,
				rgb(248, 6, 242) 43.1%,
				rgb(21, 253, 234) 43.1%,
				rgb(21, 253, 234) 100%
			  );
		}
	}
`;

export const TableWrapperPrizePool = styled.div`
	display: flex;
	margin: 20px;
	flex-direction: column;
	align-items: center;

	justify-content: center;

	table {
		background-image: linear-gradient(
			to bottom,
			rgb(21, 253, 234) 0%,
			rgb(102, 129, 235) 33%,
			rgb(248, 6, 242) 100%
		); /* the gradient */
		background-origin: border-box; /* set background to start from border-box */
		border-spacing: 5px; /* space between each cell */
	
	}

	thead {
		border: 6px solid transparent;
	}

	tbody {
		border-top: 4px solid transparent;
	}

		tr {
			border-top: 4px solid transparent;
			border-bottom: 3px solid transparent;
		}
	

	th {
		background: rgb(0, 1, 34);
		color: white;

	
	}

	td {
		padding: 0;
		border: 0;

		div:first-of-type {
			background: rgb(0, 1, 34);
			padding: 8px;

			color: white;
			 display: flex;    
   		 justify-content: flex-start;
			
}


		}
	}

	@media (max-width: ${Breakpoints.S}) {
		margin: 0;
		display: block;

		table {
			max-width: 100%;
			min-width: 100%;
			margin-bottom: 20px;
		}
	}
`;

export const Pagination = styled(TablePagination)`
	font-family: Notable-regular;
`;

export const Header = styled.div`
	padding-bottom: 20px;
`;

export const WallletWrapper = styled.div`
	padding: 20px;
	color: white;

	display: flex;
	flex-direction: column;
	align-items: center;
	input {
		width: 500px;
	}
	button {
		margin-top: 10px;
	}

	button:first-of-type {
		margin-right: 10px;
	}
	@media (max-width: ${Breakpoints.S}) {
		padding: 20px 0;
		display: block;
		button {
			width: 100%;
		}

		table {
			max-width: 100%;
			min-width: 100%;
			margin-bottom: 20px;
		}

		input {
			min-width: calc(100vw - 20px);
			width: 100%;
		}
	}
`;

export const YourDataWrapper = styled.div`
	padding-top: 20px;
`;

export const InputWrapper = styled.div`
	.MuiInputBase-root {
		color: white;

		border-radius: 0 24px 0 24px !important;
		border: solid 4px rgb(21, 253, 234) !important;

		svg {
			fill: white;
		}

		button {
			margin: 0;
		}
		input {
			::placeholder {
				color: white;
				opacity: 1; /* Firefox */
			}

			::-ms-input-placeholder {
				/* Edge 12 -18 */
				color: white;
			}
		}
	}
	@media (max-width: ${Breakpoints.S}) {
		button {
			display: none;
		}
	}
`;

export const InputWrapperEmail = styled.div`
	.MuiInputBase-root {
		color: white;

		border-radius: 0 24px 0 24px !important;
		border: solid 2px rgb(21, 253, 234) !important;

		svg {
			fill: white;
		}

		button {
			margin: 0;
		}
		input {
			::placeholder {
				color: white;
				opacity: 1; /* Firefox */
			}

			::-ms-input-placeholder {
				/* Edge 12 -18 */
				color: white;
			}
		}
	}
	@media (max-width: ${Breakpoints.S}) {
		button {
			display: none;
		}
	}
`;
export const StatsContainer = styled.div`
	// display: flex;
	// flex-direction: column;
	// align-items: center;
`;

export const IconWrapper = styled.div<{ flip: boolean }>`
	svg {
		cursor: ${props => (props.flip ? "" : "pointer")};
		opacity: ${props => (props.flip ? "0.5" : "1")};
		path {
			fill: rgb(21, 253, 234);
			${props =>
				props.flip &&
				`
				animation: spin-reverse 0.5s  linear;

			`}
		}
	}
	@keyframes spin-reverse {
		0% {
			transform: rotate(0deg);
			transform-origin: 50% 50%;
		}
		100% {
			transform: rotate(180deg);
			transform-origin: 50% 50%;
		}
	}
`;

export const Empty = styled.div`
	max-height: auto;
`;

export const SaveButton = styled(Button)`
	background-color: rgb(21, 253, 234) !important;
	border-radius: 24px 0 24px 0 !important;
	border: solid 2px rgb(21, 253, 234) !important;

	button {
		background-color: rgb(21, 253, 234) !important;
		border-radius: 25% 10% !important;
		border: solid 2px rgb(21, 253, 234) !important;
	}

	svg {
		width: 20px;
		height: 20px;
		path {
			stroke: black;
		}
	}
`;

export const ContactUsButton = styled(Button)`
	background-color: rgb(21, 253, 234) !important;
	border-radius: 24px 0 24px 0 !important;
	border: solid 2px rgb(21, 253, 234) !important;

	button {
		background-color: rgb(21, 253, 234) !important;
		border-radius: 25% 10% !important;
		border: solid 2px rgb(21, 253, 234) !important;
	}

	svg {
		width: 20px;
		height: 20px;
	}
`;

export const DeleteButton = styled(Button)`
	border-radius: 0 24px 0 24px !important;
	border: solid 4px rgb(21, 253, 234) !important;
	button {
		background-color: none !important;
		border: solid 2px rgb(21, 253, 234) !important;
		border-radius: 25% 10% !important;
	}

	svg {
		width: 20px;
		height: 20px;
	}
`;

export const TabsWrapper = styled.div`
	display: flex;
	gap: 30px;

	@media (max-width: ${Breakpoints.M}) {
		flex-direction: column;
		gap: 10px;
	}
`;

export const TabsWrapperRanks = styled.div`
	display: flex;
	gap: 60px;

	@media (max-width: ${Breakpoints.M}) {
		flex-direction: column;
		gap: 10px;
	}
`;

export const HeaderPointsGainers = styled.div`
	border: solid 3px rgb(21, 253, 234);
	padding: 10px;

	@media (max-width: ${Breakpoints.M}) {
		margin-bottom: 20px;
	}
`;

export const TabsWrapperRank = styled.div`
	display: flex;
	flex-direction: column;
`;

export const CustomBox = styled.div`
  position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   	border-radius: 0 24px 0 24px;
	border: solid 2px rgb(21, 253, 234)!important;
	background-color: rgb(0, 1, 34);
	padding: 20px;
	color: white !important;
}
`;

export const Textarea = styled(BaseTextareaAutosize)(
	({ theme }) => `
    width: 400px;

	@media (max-width: ${Breakpoints.S}) {
		width: 300px;
	}
	
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: white;
    background:none;
    border-radius: 0 24px 0 24px;
	border: solid 2px rgb(21, 253, 234) !important;
   

  `
);

export const BoxColumn = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin-bottom: 10px;
	width: 100%;
	.MuiTextField-root {
		min-width: 100% !important;
	}
`;

export const SendWrapper = styled.div`
	display: flex;
	justify-content: center;
	button {
		width: 140px;
	}
`;

export const TooltipElement = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
	cursor: pointer;
	margin-bottom: 10px;
	svg {
		width: 30px;
	}
`;

export const GainersHeader = styled.div``;

export const ButtonSendWrapper = styled.div<{ isSentryEnable?: boolean }>`
	display: flex;

	@media (max-width: ${Breakpoints.XS}) {
		button {
			width: 100%;
		}
	}

	${props =>
		!props.isSentryEnable &&
		css`
			button {
				cursor: not-allowed;
				background-color: grey !important;
				border: solid 2px grey !important;
			}
			p {
				margin-left: 10px;
			}
		`};
`;
