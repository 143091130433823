import * as React from "react";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { useEffect, useState } from "react";
import {
	TableWrapper,
	WallletWrapper,
	YourDataWrapper,
	InputWrapper,
	SaveButton,
	DeleteButton
} from "./App.styles";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import DeleteIcon from "@mui/icons-material/Delete";

// import ClearIcon from "@mui/icons-material/ClearIcon";

import { ReactComponent as SendIcon } from "./../src/icons/send.svg";
import { ReactComponent as RemoveIcon } from "./../src/icons/remove.svg";
import TableBody from "@mui/material/TableBody";
import {
	Box,
	Button,
	IconButton,
	Paper,
	TableContainer,
	TextField,
	Typography
} from "@mui/material";
import { off } from "process";
import RowComponent from "./Row";

const YourWallet = ({ tableData, totalPoints }: any) => {
	const [wallet, setWallet] = useState<string>(
		localStorage.getItem("your-wallet") || ""
	);
	const [savedWallet, setSavedWallet] = useState(
		localStorage.getItem("your-wallet") || ""
	);

	const [yourData, setYourData] = useState(null);
	const [index, setIndex] = useState(0);

	useEffect(() => {
		if (savedWallet) {
			const walletIndex = tableData?.findIndex(
				(e: any) =>
					e.wallet?.toLocaleLowerCase() === savedWallet?.toLocaleLowerCase()
			);

			setIndex(walletIndex);
			setYourData(tableData[walletIndex]);
		}
	}, [tableData, savedWallet]);

	const handleClearClick = () => {
		setWallet("");
	};

	const removeWallet = () => {
		setWallet("");
		setSavedWallet("");
		localStorage.setItem("your-wallet", "");
	};

	const handleSaveClick = () => {
		setWallet(wallet);
		setSavedWallet(wallet);
		localStorage.setItem("your-wallet", wallet);
	};

	return (
		<WallletWrapper>
			<InputWrapper>
				<TextField
					id="outlined-controlled"
					placeholder="Add your wallet"
					value={wallet}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						setWallet(event.target.value);
					}}
					InputProps={{
						endAdornment: (
							<IconButton
								sx={{ visibility: wallet ? "visible" : "hidden" }}
								onClick={handleClearClick}
							>
								<DeleteIcon />
							</IconButton>
						)
					}}
				/>
			</InputWrapper>
			<div>
				<SaveButton
					variant="contained"
					disabled={!wallet}
					endIcon={<SendIcon />}
					onClick={handleSaveClick}
				>
					<Typography
						textTransform="uppercase"
						color={`rgb(0,1,34)`}
						fontWeight="700"
						fontFamily="Dosis"
						align="center"
					>
						Save your wallet
					</Typography>
				</SaveButton>
				<DeleteButton
					variant="outlined"
					disabled={!wallet}
					startIcon={<RemoveIcon />}
					onClick={removeWallet}
				>
					<Typography
						textTransform="uppercase"
						color={`rgb(21, 253, 234)`}
						fontWeight="700"
						fontFamily="Dosis"
						align="center"
					>
						Delete your wallet from memory
					</Typography>
				</DeleteButton>
			</div>
			<YourDataWrapper>
				{yourData ? (
					<TableWrapper>
						<TableContainer component={Paper}>
							<Table
								sx={{ minWidth: 500, maxWidth: 800 }}
								aria-label="custom pagination table"
							>
								<TableHead>
									<TableRow>
										<TableCell>Current Position</TableCell>
										<TableCell>Wallet Address</TableCell>
										<TableCell>Total Current Points</TableCell>
										<TableCell>Share [%]</TableCell>
										<TableCell> SP (Survivor Points)</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{[yourData].map(
										(row: {
											wallet: string;
											initialState: number;
											share: number | null;
											points: number | null;
											origin: number;
											summary: number;
										}) => (
											<RowComponent
												key={row.wallet}
												row={row}
												index={index + 1}
												totalPoints={totalPoints}
												tableData={tableData}
											></RowComponent>
										)
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</TableWrapper>
				) : (
					<div>Wallet not found, please provide your Polygon Wallet</div>
				)}
			</YourDataWrapper>
		</WallletWrapper>
	);
};

export default YourWallet;
