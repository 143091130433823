export const hourly = {
	previous: {
		date: "2024-02-13T12:00:20.353Z",
		table: [
			{
				wallet: "0xc5BD540488dBF76e5D175eAe6eE91370e9e2896b",
				initialState: 639643,
				summary: 645248,
				share: "fetch from data 5605",
				points: 5605,
				origin: 0
			},
			{
				wallet: "0x8E7e1Eb798C4757667AcbB716eB641eD3eB3e18F",
				initialState: 428123,
				summary: 435238,
				share: "fetch from data 7115",
				points: 7115,
				origin: 1
			},
			{
				wallet: "0xD842dACB97a131C0c2C7f2F5f4AF5D49BbA6E2A8",
				initialState: 214874,
				summary: 221227,
				share: "fetch from data 6353",
				points: 6353,
				origin: 2
			},
			{
				wallet: "0xE559F80Da408d5E08f42004C0Ae143D6456f3708",
				initialState: 174607,
				summary: 189607,
				share: "fetch from data 15000",
				points: 15000,
				origin: 3
			},
			{
				wallet: "0x797702f79F248DAf949eb409Fec179A0b2737AE9",
				initialState: 146114,
				summary: 146114,
				share: "fetch from data 0",
				points: 0,
				origin: 4
			},
			{
				wallet: "0x00f49f11Fd49E9d1c2576a7da3809fE5F56B2669",
				initialState: 138615,
				summary: 139817,
				share: "fetch from data 1202",
				points: 1202,
				origin: 5
			},
			{
				wallet: "0xb8320E68Bc588ffcFcDbb13d85B61d7AC1735c5A",
				initialState: 136998,
				summary: 139810,
				share: "fetch from data 2812",
				points: 2812,
				origin: 6
			},
			{
				wallet: "0xCcf8Cfa0448726Aade46df1bb0C143Ad54c2675B",
				initialState: 126474,
				summary: 128866,
				share: "fetch from data 2392",
				points: 2392,
				origin: 7
			},
			{
				wallet: "0x99588d21D07FA23B22685E42B1E47c8bCa551093",
				initialState: 107645,
				summary: 111285,
				share: "fetch from data 3640",
				points: 3640,
				origin: 8
			},
			{
				wallet: "0x81EE4B73d0d7876aeaeAaF8CFd7Fc5FF49F8e861",
				initialState: 88735,
				summary: 88775,
				share: "fetch from data 40",
				points: 40,
				origin: 9
			},
			{
				wallet: "0x8297b1c7d61d71d1Bc077079A63635fbf37c388E",
				initialState: 72919,
				summary: 81557,
				share: "fetch from data 8638",
				points: 8638,
				origin: 10
			},
			{
				wallet: "0xD486B7d8969DB80e2A90A4639e477513575Aa5d9",
				initialState: 55375,
				summary: 70375,
				share: "fetch from data 15000",
				points: 15000,
				origin: 15
			},
			{
				wallet: "0x026709ec865a7cfe1c4273eccfc6b180729cf0eb",
				initialState: 65489,
				summary: 67960,
				share: "fetch from data 2471.0000000000005",
				points: 2471,
				origin: 12
			},
			{
				wallet: "0xb69B2222B0Da0fE03b4096fa6BA4f15Bfa4B3e30",
				initialState: 66262,
				summary: 66542,
				share: "fetch from data 280",
				points: 280,
				origin: 11
			},
			{
				wallet: "0x78c20cD7C8451a7617cf2C8095fF1a890662ec4a",
				initialState: 60815,
				summary: 65915,
				share: "fetch from data 5100",
				points: 5100,
				origin: 14
			},
			{
				wallet: "0x0fab269Ce2bF345A79DA5713f4631E344aCCc44f",
				initialState: 63212,
				summary: 63383,
				share: "fetch from data 171",
				points: 171,
				origin: 13
			},
			{
				wallet: "0x2f184902bc9ae442250613c9d2d044e7f6b6cfb7",
				initialState: 50174,
				summary: 50974,
				share: "fetch from data 800",
				points: 800,
				origin: 16
			},
			{
				wallet: "0xC31Cb557175bdE9645C991520aDf4dE194dA4f09",
				initialState: 49231,
				summary: 49321,
				share: "fetch from data 90",
				points: 90,
				origin: 18
			},
			{
				wallet: "0x9E3111685bf7e9B97a767C4a4bE4E664A01a74Ac",
				initialState: 49289,
				summary: 49289,
				share: "fetch from data 0",
				points: 0,
				origin: 17
			},
			{
				wallet: "0xe5988b25f596c0d7c2ecec5faebeed164f697680",
				initialState: 47392,
				summary: 47392,
				share: "fetch from data 0",
				points: 0,
				origin: 19
			},
			{
				wallet: "0x2A19f9A1B943d4F3730Ebeb4a9AB256D969e5b3F",
				initialState: 42561,
				summary: 42775,
				share: "fetch from data 214",
				points: 214,
				origin: 21
			},
			{
				wallet: "0xB9aaAe94279c28aaF5bdc58c35FaB88b70C9D9B2",
				initialState: 42647,
				summary: 42647,
				share: "fetch from data 0",
				points: 0,
				origin: 20
			},
			{
				wallet: "0x5ae6E2bde3EE2ad6FCfdBff2f19f97a1C54Ca56B",
				initialState: 40562,
				summary: 41362,
				share: "fetch from data 800",
				points: 800,
				origin: 22
			},
			{
				wallet: "0x99908ED8bbdd0d4302D975f539Dac5eC876B6730",
				initialState: 36404,
				summary: 36404,
				share: "fetch from data 0",
				points: 0,
				origin: 23
			},
			{
				wallet: "0x09571339AA35d79190553a8C9af6388cb1C0DF0b",
				initialState: 30264,
				summary: 36244,
				share: "fetch from data 5980",
				points: 5980,
				origin: 27
			},
			{
				wallet: "0x54a576bd48a48ef65f95edfe8173dcaa9415fe9e",
				initialState: 29328,
				summary: 36080,
				share: "fetch from data 6752",
				points: 6752,
				origin: 28
			},
			{
				wallet: "0x7bF2426C652Db59Ef7f1bB31f5DA118c2d3DB172",
				initialState: 35711,
				summary: 35711,
				share: "fetch from data 0",
				points: 0,
				origin: 24
			},
			{
				wallet: "0xc953744F699cd24f40173939190698B88F4bBb41",
				initialState: 34651,
				summary: 35471,
				share: "fetch from data 820",
				points: 820,
				origin: 25
			},
			{
				wallet: "0x3C86339862BD1139d461B0D3030312499707a5b2",
				initialState: 34589,
				summary: 34685,
				share: "fetch from data 96",
				points: 96,
				origin: 26
			},
			{
				wallet: "0xf19D1eF339455F143EF5617cf311A1FA0E52274a",
				initialState: 28759,
				summary: 29059,
				share: "fetch from data 300",
				points: 300,
				origin: 29
			},
			{
				wallet: "0x4A180E3964f80c4C2Ee540E0a029E308Cc2cdB5A",
				initialState: 21327,
				summary: 28399,
				share: "fetch from data 7072",
				points: 7072,
				origin: 33
			},
			{
				wallet: "0xf8131548aa0871d842647152c08040d984d9fe00",
				initialState: 26204,
				summary: 26204,
				share: "fetch from data 0",
				points: 0,
				origin: 30
			},
			{
				wallet: "0xBa7543Df19d6c207B9eC067F0146Dc88Ca3cFD9D",
				initialState: 23119,
				summary: 23919,
				share: "fetch from data 800",
				points: 800,
				origin: 31
			},
			{
				wallet: "0x586326749D35C7a55da421AcE7A6B2Cb871d366E",
				initialState: 18530,
				summary: 22737,
				share: "fetch from data 4207",
				points: 4207,
				origin: 35
			},
			{
				wallet: "0x5f3a73Ac616bAdc60B8a65Df0e5C20C5F5B605d4",
				initialState: 22427,
				summary: 22434,
				share: "fetch from data 7",
				points: 7,
				origin: 32
			},
			{
				wallet: "0x3214f505e554e1f2eb3d8928423ae20466d0acb7",
				initialState: 16353,
				summary: 19233,
				share: "fetch from data 2880",
				points: 2880,
				origin: 42
			},
			{
				wallet: "0xDcff9206A96358c451736Ab34f96b02973EF7b54",
				initialState: 19045,
				summary: 19045,
				share: "fetch from data 0",
				points: 0,
				origin: 34
			},
			{
				wallet: "0x3EdA28f6f210C269180b5ee8B3138C751796286E",
				initialState: 18276,
				summary: 18276,
				share: "fetch from data 0",
				points: 0,
				origin: 36
			},
			{
				wallet: "0x77444A92c8294d40a097DF15A12E62Bb4BFA820C",
				initialState: 18083,
				summary: 18083,
				share: "fetch from data 0",
				points: 0,
				origin: 37
			},
			{
				wallet: "0x3dbC70570aF48e706AA9723313d4De9971102981",
				initialState: 17378,
				summary: 17783,
				share: "fetch from data 405",
				points: 405,
				origin: 39
			},
			{
				wallet: "0x20E6aCD7b99e1EA3231f617D52802d6F751084c7",
				initialState: 17485,
				summary: 17485,
				share: "fetch from data 0",
				points: 0,
				origin: 38
			},
			{
				wallet: "0x4c6De86AE62DAb4296126Ce6CD34Efb21E18427B",
				initialState: 16844,
				summary: 16844,
				share: "fetch from data 0",
				points: 0,
				origin: 40
			},
			{
				wallet: "0x56dC05030AdE425Ee58E310776EA46e785Eb2203",
				initialState: 16602,
				summary: 16604,
				share: "fetch from data 2",
				points: 2,
				origin: 41
			},
			{
				wallet: "0xd26Ad5b02538975efB420f113e17Ab96D3EDCc2a",
				initialState: 16172,
				summary: 16472,
				share: "fetch from data 300",
				points: 300,
				origin: 43
			},
			{
				wallet: "0xF86AEaabD093E817fF8e866083e93d18f07DFa6E",
				initialState: 11831,
				summary: 16424,
				share: "fetch from data 4593",
				points: 4593,
				origin: 54
			},
			{
				wallet: "0x2aca26310D06B16e4b1614D21Ea490633dE80145",
				initialState: 15467,
				summary: 15480,
				share: "fetch from data 13",
				points: 13,
				origin: 44
			},
			{
				wallet: "0x29D6ee592c6A9935E7e9D53fA44bD1eFaD44d30a",
				initialState: 13991,
				summary: 14361,
				share: "fetch from data 370",
				points: 370,
				origin: 46
			},
			{
				wallet: "0x1F2C87b25830C710a56B2aA7035342d99BC1cBbA",
				initialState: 14194,
				summary: 14194,
				share: "fetch from data 0",
				points: 0,
				origin: 45
			},
			{
				wallet: "0x8f53615891001b21cf2b9aA16b7B445Df50F2cF2",
				initialState: 12842,
				summary: 14072,
				share: "fetch from data 1230",
				points: 1230,
				origin: 50
			},
			{
				wallet: "0xa0D843Ca1C8AAF89F7510aeFc432D303a5661c1C",
				initialState: 13889,
				summary: 13889,
				share: "fetch from data 0",
				points: 0,
				origin: 47
			},
			{
				wallet: "0x0F587435fd933646aD250CeD9587169513a07306",
				initialState: 13000,
				summary: 13827,
				share: "fetch from data 827",
				points: 827,
				origin: 48
			},
			{
				wallet: "0xD16be7646F99a9Ae229370c9E50c1FCd800b518d",
				initialState: 12918,
				summary: 13698,
				share: "fetch from data 780",
				points: 780,
				origin: 49
			},
			{
				wallet: "0xf6Ab6Cf23B2C192aEb401cD2DBafF528c8DEF8a1",
				initialState: 12758,
				summary: 12792,
				share: "fetch from data 34",
				points: 34,
				origin: 51
			},
			{
				wallet: "0xe44658fdE9b3b9714382eDCB32D86d48Feca4DD9",
				initialState: 12518,
				summary: 12520,
				share: "fetch from data 2",
				points: 2,
				origin: 52
			},
			{
				wallet: "0x1d33979AD9A3367d5119acd96AF0eA22606D2230",
				initialState: 10603,
				summary: 12081,
				share: "fetch from data 1478",
				points: 1478,
				origin: 57
			},
			{
				wallet: "0xABDc83555B28c30604F74dC200b601175B8C0504",
				initialState: 11942,
				summary: 11942,
				share: "fetch from data 0",
				points: 0,
				origin: 53
			},
			{
				wallet: "0xFC88a072ceFcf9D6E29a28dA0dce56C818486dA7",
				initialState: 10614,
				summary: 11746,
				share: "fetch from data 1132",
				points: 1132,
				origin: 56
			},
			{
				wallet: "0x1606acae9e1e13d4fcd9677016f6aefd9ad7a496",
				initialState: 11308,
				summary: 11308,
				share: "fetch from data 0",
				points: 0,
				origin: 55
			},
			{
				wallet: "0x367A9EEe3a430F8aE7fc056BbE3ECA604653569C",
				initialState: 8891,
				summary: 10700,
				share: "fetch from data 1809",
				points: 1809,
				origin: 63
			},
			{
				wallet: "0x7F2caA45F27D6129379f8F38485D21DF67F863F2",
				initialState: 10518,
				summary: 10518,
				share: "fetch from data 0",
				points: 0,
				origin: 58
			},
			{
				wallet: "0x8737dd01A5f8C4766E742C9E00C943c14Cd3cD66",
				initialState: 8217,
				summary: 10367,
				share: "fetch from data 2150",
				points: 2150,
				origin: 70
			},
			{
				wallet: "0xb3a90f379eA9fa8F7747ceDb95a6520140eF21f7",
				initialState: 10000,
				summary: 10000,
				share: "fetch from data 0",
				points: 0,
				origin: 59
			},
			{
				wallet: "0xdde733b93c63a777b9ff1de617bbf54db16410d0",
				initialState: 10000,
				summary: 10000,
				share: "fetch from data 0",
				points: 0,
				origin: 60
			},
			{
				wallet: "0x97698c4F2e6a7b1eB7daA6a21d4B7B3D90C59Bc6",
				initialState: 9950,
				summary: 9950,
				share: "fetch from data 0",
				points: 0,
				origin: 61
			},
			{
				wallet: "0xDdcB84CDC2f84C418740608D52c3d00DEc121577",
				initialState: 3941,
				summary: 9760,
				share: "fetch from data 5819",
				points: 5819,
				origin: 101
			},
			{
				wallet: "0x4D740ffdB50FEf24865ecaA326bbb1Cc351DE859",
				initialState: 9175,
				summary: 9741,
				share: "fetch from data 566",
				points: 566,
				origin: 62
			},
			{
				wallet: "0x3641E7897b48AF17369dc83aB7779a7430C475AE",
				initialState: 8649,
				summary: 9249,
				share: "fetch from data 600",
				points: 600,
				origin: 65
			},
			{
				wallet: "0xE8C5eCad69CD798A6AfF5c9F10fb668351EEe3aF",
				initialState: 8845,
				summary: 8845,
				share: "fetch from data 0",
				points: 0,
				origin: 64
			},
			{
				wallet: "0xe6351265192C6eF26Fa516144a051C651f35f66c",
				initialState: 8637,
				summary: 8637,
				share: "fetch from data 0",
				points: 0,
				origin: 66
			},
			{
				wallet: "0xC6A1dF806552d1d21E7e54065aD507b5A9FceE49",
				initialState: 8542,
				summary: 8542,
				share: "fetch from data 0",
				points: 0,
				origin: 67
			},
			{
				wallet: "0xd819a67EEe457bd0b2BD77da918f205b483caf2c",
				initialState: 8536,
				summary: 8536,
				share: "fetch from data 0",
				points: 0,
				origin: 68
			},
			{
				wallet: "0xf38b09737b41D08aBAD2D8a37264a8Cb6c05A710",
				initialState: 8473,
				summary: 8473,
				share: "fetch from data 0",
				points: 0,
				origin: 69
			},
			{
				wallet: "0x94001519b1cFcAc6A77841778Fd58599C75dB1cE",
				initialState: 8204,
				summary: 8204,
				share: "fetch from data 0",
				points: 0,
				origin: 71
			},
			{
				wallet: "0x6e83A89e0C52330098967547c05aDDAd13D8616C",
				initialState: 7966,
				summary: 7966,
				share: "fetch from data 0",
				points: 0,
				origin: 72
			},
			{
				wallet: "0x5a168ccbC4754fbAF66a2F1d5d3e28acf45a542b",
				initialState: 6651,
				summary: 7929,
				share: "fetch from data 1278",
				points: 1278,
				origin: 79
			},
			{
				wallet: "0x44b4397b4bF07e8F9CC822aCaA699dB116F66496",
				initialState: 7624,
				summary: 7883,
				share: "fetch from data 259",
				points: 259,
				origin: 73
			},
			{
				wallet: "0x149f06b4bDEB1E8c4C09F4619c4485f395aAA081",
				initialState: 7523,
				summary: 7644,
				share: "fetch from data 121",
				points: 121,
				origin: 74
			},
			{
				wallet: "0xf56c7EBF73203B89A107eB8aE951D5433E3dc307",
				initialState: 3065,
				summary: 7610,
				share: "fetch from data 4545",
				points: 4545,
				origin: 119
			},
			{
				wallet: "0xB0DE5547DB48bDacF0596000718956A700197453",
				initialState: 6806,
				summary: 7285,
				share: "fetch from data 479",
				points: 479,
				origin: 77
			},
			{
				wallet: "0xA5b4d58F69774A7eEA5A17cbf0B685cB0921aa11",
				initialState: 7212,
				summary: 7212,
				share: "fetch from data 0",
				points: 0,
				origin: 75
			},
			{
				wallet: "0xb5A75c4A40F10B68A614A7aDFf23623f47b317fC",
				initialState: 7059,
				summary: 7059,
				share: "fetch from data 0",
				points: 0,
				origin: 76
			},
			{
				wallet: "0x7774643697FA3D44c388454BBFB982C91d6ed405",
				initialState: 2929,
				summary: 7005,
				share: "fetch from data 4076",
				points: 4076,
				origin: 123
			},
			{
				wallet: "0x1C940Ae97bbeF2F9A20d079EB9AE6694a512f57b",
				initialState: 2188,
				summary: 6731,
				share: "fetch from data 4543",
				points: 4543,
				origin: 140
			},
			{
				wallet: "0x18912511C6Aed6223e497BA7Ceaa02EF3c398cD7",
				initialState: 6676,
				summary: 6676,
				share: "fetch from data 0",
				points: 0,
				origin: 78
			},
			{
				wallet: "0x7add8DD4A8719391564377b608AD574dA5CC501a",
				initialState: 6646,
				summary: 6646,
				share: "fetch from data 0",
				points: 0,
				origin: 80
			},
			{
				wallet: "0x1e9F7bf95Fa16834B1189533855d5403d4A596B6",
				initialState: 6616,
				summary: 6616,
				share: "fetch from data 0",
				points: 0,
				origin: 81
			},
			{
				wallet: "0x8ecAcB6Ba16389524cCAF26D599632dF22C32FE7",
				initialState: 4356,
				summary: 6420,
				share: "fetch from data 2064",
				points: 2064,
				origin: 96
			},
			{
				wallet: "0x5424dF78c8C69eCC53D0713DBA36E882930b4b03",
				initialState: 6106,
				summary: 6206,
				share: "fetch from data 100",
				points: 100,
				origin: 83
			},
			{
				wallet: "0x100fA3EE7C0fB22cA87EAD78292b071D2c5b3708",
				initialState: 3076,
				summary: 6186,
				share: "fetch from data 3110",
				points: 3110,
				origin: 118
			},
			{
				wallet: "0x3e75edc6b4AE5668f824A44044a44bf53275Dbf9",
				initialState: 6176,
				summary: 6176,
				share: "fetch from data 0",
				points: 0,
				origin: 82
			},
			{
				wallet: "0x650409019Fafa08E89cFED870eD3D084e9b5918d",
				initialState: 5450,
				summary: 6049,
				share: "fetch from data 599",
				points: 599,
				origin: 88
			},
			{
				wallet: "0xca90808fd811154c7a116e5f6ebf2062f292213a",
				initialState: 5827,
				summary: 6047,
				share: "fetch from data 220",
				points: 220,
				origin: 86
			},
			{
				wallet: "0x0F6722d52b88C01Dcc33a12A8102E5c162099e85",
				initialState: 6004,
				summary: 6004,
				share: "fetch from data 0",
				points: 0,
				origin: 84
			},
			{
				wallet: "0x015ef705d45326ad9ce55f0aaacef8d4e2ea3355",
				initialState: 6000,
				summary: 6000,
				share: "fetch from data 0",
				points: 0,
				origin: 85
			},
			{
				wallet: "0x834dD65b0a6A74EdAB260eC705F4857640e54191",
				initialState: 1882,
				summary: 5633,
				share: "fetch from data 3751.0000000000005",
				points: 3751,
				origin: 150
			},
			{
				wallet: "0x6A94d0f10b10A3fe04f8fDf9cAD7B0E08C5bc154",
				initialState: 5616,
				summary: 5616,
				share: "fetch from data 0",
				points: 0,
				origin: 87
			},
			{
				wallet: "0xAa13Ee09e4D2eA02E07924b2ba0a75A3f61b66C9",
				initialState: 3709,
				summary: 5502,
				share: "fetch from data 1793",
				points: 1793,
				origin: 107
			},
			{
				wallet: "0x0C7eb7E6C93FAC81Db9302eb0e4730067a570DDD",
				initialState: 4418,
				summary: 5430,
				share: "fetch from data 1012",
				points: 1012,
				origin: 95
			},
			{
				wallet: "0x9ceB39629389BD726F26cC20363D79AF50B0641d",
				initialState: 4932,
				summary: 5429,
				share: "fetch from data 497",
				points: 497,
				origin: 92
			},
			{
				wallet: "0x6151420D76114dd8B8613391014D59964C9Bc8d8",
				initialState: 5327,
				summary: 5349,
				share: "fetch from data 22",
				points: 22,
				origin: 89
			},
			{
				wallet: "0x752D707f95C115dA3819a6Ad20e11555BcdaE160",
				initialState: 5300,
				summary: 5300,
				share: "fetch from data 0",
				points: 0,
				origin: 90
			},
			{
				wallet: "0x62a3aAf79967A4b5491c8D272340E1545Dc2d769",
				initialState: 5011,
				summary: 5011,
				share: "fetch from data 0",
				points: 0,
				origin: 91
			},
			{
				wallet: "0xd674098Dc3AD6acC949b7cece902BE21e5C7c395",
				initialState: 3016,
				summary: 4960,
				share: "fetch from data 1944",
				points: 1944,
				origin: 121
			},
			{
				wallet: "0x9D777BBc9Bb82dC7E301dC8e5A55f92932b173E0",
				initialState: 4105,
				summary: 4895,
				share: "fetch from data 790",
				points: 790,
				origin: 99
			},
			{
				wallet: "0x3B9547E1F40B73A9Dc75f645a84A23a594d9F8f4",
				initialState: 3616,
				summary: 4883,
				share: "fetch from data 1267",
				points: 1267,
				origin: 108
			},
			{
				wallet: "0x80994485a70E298e89ee4f6C8A9E70F67Bcd6659",
				initialState: 4700,
				summary: 4700,
				share: "fetch from data 0",
				points: 0,
				origin: 93
			},
			{
				wallet: "0x525aF36d7cF0D7693E0CbB67BF8276792CC328F1",
				initialState: 3923,
				summary: 4504,
				share: "fetch from data 581",
				points: 581,
				origin: 103
			},
			{
				wallet: "0x68c6af90219B9Dd0Da54d4fa585aa78Dd11D8867",
				initialState: 4450,
				summary: 4450,
				share: "fetch from data 0",
				points: 0,
				origin: 94
			},
			{
				wallet: "0xa8413B6Debe69e2B15eEdDa879a4aCF535a1ec70",
				initialState: 4192,
				summary: 4442,
				share: "fetch from data 250",
				points: 250,
				origin: 98
			},
			{
				wallet: "0xb3c6907d27f622Aa7Cd896F3d88442fA5122E6F9",
				initialState: 3984,
				summary: 4388,
				share: "fetch from data 404",
				points: 404,
				origin: 100
			},
			{
				wallet: "0x1B67455A7Ecf1eA253f930233219e7829f0f1F49",
				initialState: 4351,
				summary: 4358,
				share: "fetch from data 7",
				points: 7,
				origin: 97
			},
			{
				wallet: "0xa1C3684E01Fab4083912f065FA9f05d1176F0293",
				initialState: 3204,
				summary: 4119,
				share: "fetch from data 915",
				points: 915,
				origin: 113
			},
			{
				wallet: "0xD443437880957010D574025C80F113c002595f8c",
				initialState: 3152,
				summary: 4019,
				share: "fetch from data 867",
				points: 867,
				origin: 115
			},
			{
				wallet: "0x7943180E055bEf9630a2d7594F9509b8613839EA",
				initialState: 3144,
				summary: 4014,
				share: "fetch from data 870",
				points: 870,
				origin: 117
			},
			{
				wallet: "0xFc5C9bD4444908f535027cc4B39F7287f7654FfC",
				initialState: 3051,
				summary: 3954,
				share: "fetch from data 903",
				points: 903,
				origin: 120
			},
			{
				wallet: "0xb43224dd0cb03bc98b6c743c3173f28b6c53eae3",
				initialState: 3928,
				summary: 3928,
				share: "fetch from data 0",
				points: 0,
				origin: 102
			},
			{
				wallet: "0x643dF7893dBD02f35e127501CF78AB06DfDA7a57",
				initialState: 3550,
				summary: 3906,
				share: "fetch from data 356",
				points: 356,
				origin: 110
			},
			{
				wallet: "0x92cdf2fe974cAA2710Df8Dee6F2C0AD8fC0BdBC9",
				initialState: 2585,
				summary: 3889,
				share: "fetch from data 1304",
				points: 1304,
				origin: 131
			},
			{
				wallet: "0x237fEe61BcBb6562498ca6320AF7F823aA79B3D1",
				initialState: 2097,
				summary: 3864,
				share: "fetch from data 1767",
				points: 1767,
				origin: 143
			},
			{
				wallet: "0xCbd20280cCba4Fa92D8B6057291F329f11fFD2D2",
				initialState: 3857,
				summary: 3857,
				share: "fetch from data 0",
				points: 0,
				origin: 104
			},
			{
				wallet: "0x510cd8afa03ee3dd66ff67bce6ddc5aeaf767f17",
				initialState: 3750,
				summary: 3750,
				share: "fetch from data 0",
				points: 0,
				origin: 105
			},
			{
				wallet: "0xb04619c52a729b6687045012bc206688680289b4",
				initialState: 3735,
				summary: 3735,
				share: "fetch from data 0",
				points: 0,
				origin: 106
			},
			{
				wallet: "0xA28473302CC3F33578be8013AE6fec7DA6B0C2fa",
				initialState: 3585,
				summary: 3585,
				share: "fetch from data 0",
				points: 0,
				origin: 109
			},
			{
				wallet: "0xfbB0Bc831F34864c90fC4bF9d320BBbd08090adF",
				initialState: 2262,
				summary: 3563,
				share: "fetch from data 1301",
				points: 1301,
				origin: 139
			},
			{
				wallet: "0x7199019069830dA0649C2f13C02f69f8A94Edcd8",
				initialState: 2751,
				summary: 3551,
				share: "fetch from data 800",
				points: 800,
				origin: 128
			},
			{
				wallet: "0xb15E6CcC1ca878F4A8627BF4fb69C9A8cCd6dF35",
				initialState: 3549,
				summary: 3549,
				share: "fetch from data 0",
				points: 0,
				origin: 111
			},
			{
				wallet: "0x436379D48fDfB1Bf682C55239E2490851d37a560",
				initialState: 2928,
				summary: 3505,
				share: "fetch from data 577",
				points: 577,
				origin: 124
			},
			{
				wallet: "0x80333B7F0E802822D3f0800175A3626e231a60B3",
				initialState: 2105,
				summary: 3498,
				share: "fetch from data 1393",
				points: 1393,
				origin: 142
			},
			{
				wallet: "0x59992E33ebd79938Cc04D7CFD659d5D3a8a140A0",
				initialState: 3275,
				summary: 3275,
				share: "fetch from data 0",
				points: 0,
				origin: 112
			},
			{
				wallet: "0x422f2975CF01a53892CF359FD3F3dd63033Ae63B",
				initialState: 3153,
				summary: 3258,
				share: "fetch from data 105",
				points: 105,
				origin: 114
			},
			{
				wallet: "0x00Db6d71a9b299ec2fffc5E420850d8E9C338F29",
				initialState: 451,
				summary: 3230,
				share: "fetch from data 2779.0000000000005",
				points: 2779,
				origin: 225
			},
			{
				wallet: "0xA2dd302B997a1543593dc61ED418dB5c5c6Ad458",
				initialState: 3150,
				summary: 3150,
				share: "fetch from data 0",
				points: 0,
				origin: 116
			},
			{
				wallet: "0xE330Ba0Dc85D74B11a4ef50b9F2dDa3A1a12f327",
				initialState: 3007,
				summary: 3097,
				share: "fetch from data 90",
				points: 90,
				origin: 122
			},
			{
				wallet: "0x1b5C00E0b32624B1F0604110d6068aC0Ced3E8b8",
				initialState: 516,
				summary: 3091,
				share: "fetch from data 2575.0000000000005",
				points: 2575,
				origin: 217
			},
			{
				wallet: "0x0626B02Da0B8238Eb6C2A29629aF415D8bd8C1Db",
				initialState: 89,
				summary: 3084,
				share: "fetch from data 2995.0000000000005",
				points: 2995,
				origin: 282
			},
			{
				wallet: "0xc2632DCC9aA2b6DBA67272e5442AB7f9384a8Ba3",
				initialState: 2886,
				summary: 2886,
				share: "fetch from data 0",
				points: 0,
				origin: 125
			},
			{
				wallet: "0x2C6f0b51Fc09676022C7eE38Ab5225c2A451e8d5",
				initialState: 2880,
				summary: 2880,
				share: "fetch from data 0",
				points: 0,
				origin: 126
			},
			{
				wallet: "0xEcf7F18530d656430dBBBb1703596C9ccF2a173E",
				initialState: 2789,
				summary: 2789,
				share: "fetch from data 0",
				points: 0,
				origin: 127
			},
			{
				wallet: "0x9d58d12702054CA448e06cc6c381023E27ee84Ff",
				initialState: 805,
				summary: 2731,
				share: "fetch from data 1926",
				points: 1926,
				origin: 192
			},
			{
				wallet: "0xcDe0C934Ac6c83AC248C5b031F2e9cA582F2354b",
				initialState: 2112,
				summary: 2717,
				share: "fetch from data 605",
				points: 605,
				origin: 141
			},
			{
				wallet: "0xE255b82DE33763956500AF754FB4D26411131FDE",
				initialState: 2600,
				summary: 2670,
				share: "fetch from data 70",
				points: 70,
				origin: 130
			},
			{
				wallet: "0x27B6dCFF8786AbBC517C75Fad20f9b4539Dc35CF",
				initialState: 2650,
				summary: 2650,
				share: "fetch from data 0",
				points: 0,
				origin: 129
			},
			{
				wallet: "0xE39eC2FFF9d73a63F7E3Cba24a1B28FAE29e78Fb",
				initialState: 1196,
				summary: 2619,
				share: "fetch from data 1423",
				points: 1423,
				origin: 171
			},
			{
				wallet: "0xdbcedaa96de3a16e3a94a357837f670bbf416590",
				initialState: 2300,
				summary: 2616,
				share: "fetch from data 316",
				points: 316,
				origin: 138
			},
			{
				wallet: "0x5203D0fDB25836653a59B925772BE504aB7dea7E",
				initialState: 2459,
				summary: 2474,
				share: "fetch from data 15",
				points: 15,
				origin: 132
			},
			{
				wallet: "0x4e34Ee67526Ad466398F124E3774E6127dB98E59",
				initialState: 101,
				summary: 2473,
				share: "fetch from data 2372",
				points: 2372,
				origin: 279
			},
			{
				wallet: "0xFd37e1E4a0bd856CFECA37E1a1e3cef38A2ae195",
				initialState: 2450,
				summary: 2450,
				share: "fetch from data 0",
				points: 0,
				origin: 133
			},
			{
				wallet: "0xe071A0FD88eBEB6AffE2BC6b31002b31499387be",
				initialState: 2431,
				summary: 2431,
				share: "fetch from data 0",
				points: 0,
				origin: 134
			},
			{
				wallet: "0x9Cfa4C3F5084CfA19f21cE037310CbD6354ab127",
				initialState: 2373,
				summary: 2373,
				share: "fetch from data 0",
				points: 0,
				origin: 135
			},
			{
				wallet: "0x6d49929b7CFD62DF922aE6A9DC1F92F3b951D172",
				initialState: 1959,
				summary: 2359,
				share: "fetch from data 400",
				points: 400,
				origin: 147
			},
			{
				wallet: "0xdf5a946C1DD12406D88d0975f6498abBbf26B5BA",
				initialState: 2346,
				summary: 2346,
				share: "fetch from data 0",
				points: 0,
				origin: 136
			},
			{
				wallet: "0x3D240abF4290688007e55A204EFf426E7b67A09a",
				initialState: 2333,
				summary: 2333,
				share: "fetch from data 0",
				points: 0,
				origin: 137
			},
			{
				wallet: "0x81346f7cC179C6a544fD1ED1E90bb53B494121C5",
				initialState: 1474,
				summary: 2274,
				share: "fetch from data 800",
				points: 800,
				origin: 161
			},
			{
				wallet: "0x1B6B795e50F49c5daBcE593cA8EBe3a740FeD53A",
				initialState: 600,
				summary: 2188,
				share: "fetch from data 1588",
				points: 1588,
				origin: 210
			},
			{
				wallet: "0x2dc092B9a3bc6E1D2dEff9BdCABd733298b3e472",
				initialState: 1965,
				summary: 2187,
				share: "fetch from data 222",
				points: 222,
				origin: 146
			},
			{
				wallet: "0x4a606534699E746bF4c384D1d2697358DCB094A6",
				initialState: 1714,
				summary: 2056,
				share: "fetch from data 342",
				points: 342,
				origin: 154
			},
			{
				wallet: "0x3681864E2C887f551B0BA999aa14b27AC586C65D",
				initialState: 2052,
				summary: 2052,
				share: "fetch from data 0",
				points: 0,
				origin: 144
			},
			{
				wallet: "0xaDAF5025EF900D5aa7eF2F8768FB370366673ec8",
				initialState: 2036,
				summary: 2036,
				share: "fetch from data 0",
				points: 0,
				origin: 145
			},
			{
				wallet: "0x5D4D08a8f8710776B1bf89dAAD1AcDc98daeA16D",
				initialState: 1898,
				summary: 1999,
				share: "fetch from data 101",
				points: 101,
				origin: 149
			},
			{
				wallet: "0xf4B22bFEF0f90C57E9b49f7C13d27815F78D1Ba6",
				initialState: 1926,
				summary: 1926,
				share: "fetch from data 0",
				points: 0,
				origin: 148
			},
			{
				wallet: "0xd6dc1f3746bFc92c3C921468612393824daa83e7",
				initialState: 1880,
				summary: 1880,
				share: "fetch from data 0",
				points: 0,
				origin: 151
			},
			{
				wallet: "0x1cF865c35C6c0226a6e98A93703F93a7eD585151",
				initialState: 1242,
				summary: 1872,
				share: "fetch from data 630",
				points: 630,
				origin: 168
			},
			{
				wallet: "0xC98585466c62a81948E17488A0393254965B9986",
				initialState: 1857,
				summary: 1857,
				share: "fetch from data 0",
				points: 0,
				origin: 152
			},
			{
				wallet: "0x73bb7a5449760f65EB5949d5e4E2d0666A609A06",
				initialState: 1000,
				summary: 1800,
				share: "fetch from data 800",
				points: 800,
				origin: 181
			},
			{
				wallet: "0x680288a5D1d7A2288ceE49cbD430Ef6B5F28eFdc",
				initialState: 1781,
				summary: 1781,
				share: "fetch from data 0",
				points: 0,
				origin: 153
			},
			{
				wallet: "0xdb72Db7E973E48cFF5223038c3d45c595BfB42f3",
				initialState: 1052,
				summary: 1739,
				share: "fetch from data 687",
				points: 687,
				origin: 178
			},
			{
				wallet: "0x902802f01Ae23F864b356eD6B80E7000Deb25342",
				initialState: 160,
				summary: 1654,
				share: "fetch from data 1494",
				points: 1494,
				origin: 271
			},
			{
				wallet: "0xeE6De9E9cF0b9F77605BE39244ac488B3863F067",
				initialState: 1616,
				summary: 1616,
				share: "fetch from data 0",
				points: 0,
				origin: 155
			},
			{
				wallet: "0xC3405F9231F35d188c92a25B5706841Df1b016e3",
				initialState: 909,
				summary: 1616,
				share: "fetch from data 707",
				points: 707,
				origin: 189
			},
			{
				wallet: "0x1DB34F232023231f83f1fa53C73A0Bf9627e765A",
				initialState: 1108,
				summary: 1608,
				share: "fetch from data 500",
				points: 500,
				origin: 177
			},
			{
				wallet: "0xd2CBA9B7c27412724cCC5c4F941389ceeC89bE63",
				initialState: 1600,
				summary: 1600,
				share: "fetch from data 0",
				points: 0,
				origin: 156
			},
			{
				wallet: "0x537102197c88b050965e523D508Cd4528Eff5537",
				initialState: 1149,
				summary: 1556,
				share: "fetch from data 407",
				points: 407,
				origin: 174
			},
			{
				wallet: "0x76Ed0D9bb3EA2D498F044DA9D1eC45B66D883127",
				initialState: 1542,
				summary: 1542,
				share: "fetch from data 0",
				points: 0,
				origin: 157
			},
			{
				wallet: "0x45f31d955197e8BBCE3f99a06eA9c46FE07EEefb",
				initialState: 1540,
				summary: 1540,
				share: "fetch from data 0",
				points: 0,
				origin: 158
			},
			{
				wallet: "0x5a4d475DF4d1ec06992364FeFB6Ab0c9Fd502F8c",
				initialState: 1535,
				summary: 1535,
				share: "fetch from data 0",
				points: 0,
				origin: 159
			},
			{
				wallet: "0x76b15fBd87f7D48c28A77BdBC94ab4FaDb546038",
				initialState: 308,
				summary: 1535,
				share: "fetch from data 1227",
				points: 1227,
				origin: 245
			},
			{
				wallet: "0xcf7E658897f871562103B97eefE1F9022C697a57",
				initialState: 1175,
				summary: 1514,
				share: "fetch from data 339",
				points: 339,
				origin: 172
			},
			{
				wallet: "0x0C581AA72E35f786A957d3Abb0D1Ee10a80E2b92",
				initialState: 1501,
				summary: 1501,
				share: "fetch from data 0",
				points: 0,
				origin: 160
			},
			{
				wallet: "0xFCf97B2482F1F06F533f7EBf2b2d4612324dC464",
				initialState: 479,
				summary: 1489,
				share: "fetch from data 1010",
				points: 1010,
				origin: 222
			},
			{
				wallet: "0xdfa7012984B69451af9633ccf31627cCF7BB471C",
				initialState: 1465,
				summary: 1465,
				share: "fetch from data 0",
				points: 0,
				origin: 162
			},
			{
				wallet: "0xB0136825be910fDe99401EB62b7C992d7f9FC243",
				initialState: 519,
				summary: 1437,
				share: "fetch from data 918",
				points: 918,
				origin: 215
			},
			{
				wallet: "0xD86d973Be3F9E41a4Cf94D3Cd853b0427087Bc40",
				initialState: 1390,
				summary: 1390,
				share: "fetch from data 0",
				points: 0,
				origin: 163
			},
			{
				wallet: "0xFbE5f36268E39965A53a70a8648c53A8FDA3f866",
				initialState: 1390,
				summary: 1390,
				share: "fetch from data 0",
				points: 0,
				origin: 164
			},
			{
				wallet: "0xF38D3252EdAf849A936A9aDbD2F5931E5467227d",
				initialState: 8,
				summary: 1389,
				share: "fetch from data 1381",
				points: 1381,
				origin: 305
			},
			{
				wallet: "0x6fAB12c14bA2c632C7142a77E864d188Fbf4d2E2",
				initialState: 1350,
				summary: 1350,
				share: "fetch from data 0",
				points: 0,
				origin: 165
			},
			{
				wallet: "0x6bD23c065CcF4A728A560673eb1748c913C94e99",
				initialState: 1350,
				summary: 1350,
				share: "fetch from data 0",
				points: 0,
				origin: 166
			},
			{
				wallet: "0x9700c58e1c6a1e5fca5db5f62e477eb182fe3da4",
				initialState: 1334,
				summary: 1334,
				share: "fetch from data 0",
				points: 0,
				origin: 167
			},
			{
				wallet: "0x2caac220865b9B3954A2FdA2a3B837c51Cc432b4",
				initialState: 1139,
				summary: 1299,
				share: "fetch from data 160",
				points: 160,
				origin: 175
			},
			{
				wallet: "0x7E850f937a7eE624f2C904A328783f5C34d66881",
				initialState: 487,
				summary: 1249,
				share: "fetch from data 762",
				points: 762,
				origin: 220
			},
			{
				wallet: "0xD0dd9Df01cA8125C821CBA2547BfbD4993007cF1",
				initialState: 1239,
				summary: 1239,
				share: "fetch from data 0",
				points: 0,
				origin: 169
			},
			{
				wallet: "0x70696dDf3DaA2599643c72a533faE7E170c7bea4",
				initialState: 1200,
				summary: 1200,
				share: "fetch from data 0",
				points: 0,
				origin: 170
			},
			{
				wallet: "0x11Fca83a1E3C2Bde1B8b11Bf2140c0703FB59111",
				initialState: 1150,
				summary: 1150,
				share: "fetch from data 0",
				points: 0,
				origin: 173
			},
			{
				wallet: "0x6d6B17C89Ba207C0e71aA421dDAf47D44A54F459",
				initialState: 1121,
				summary: 1121,
				share: "fetch from data 0",
				points: 0,
				origin: 176
			},
			{
				wallet: "0xA286609f7B427fC802012Fca526D87713835abFf",
				initialState: 729,
				summary: 1104,
				share: "fetch from data 375",
				points: 375,
				origin: 197
			},
			{
				wallet: "0x87C1CDB88bE4E10880828Ec289DB46319858a050",
				initialState: 1035,
				summary: 1037,
				share: "fetch from data 2",
				points: 2,
				origin: 179
			},
			{
				wallet: "0x806Be734eE0f89e9B00b384d5E021F7eA4Fc1FEF",
				initialState: 744,
				summary: 1022,
				share: "fetch from data 278",
				points: 278,
				origin: 196
			},
			{
				wallet: "0xA59429AA760e75C22340a5331a889dC11e69feb0",
				initialState: 705,
				summary: 1015,
				share: "fetch from data 310",
				points: 310,
				origin: 200
			},
			{
				wallet: "0xF0626F4CC2D38677cEAD17f041dDe45fe625B85D",
				initialState: 798,
				summary: 1008,
				share: "fetch from data 210",
				points: 210,
				origin: 194
			},
			{
				wallet: "0xc9b03837276B9F224eB5A03ccbB57438d9182E9f",
				initialState: 1007,
				summary: 1007,
				share: "fetch from data 0",
				points: 0,
				origin: 180
			},
			{
				wallet: "0xcF715e62D18ce2d63157cBbA31b905Ac1cC8cF90",
				initialState: 1000,
				summary: 1000,
				share: "fetch from data 0",
				points: 0,
				origin: 182
			},
			{
				wallet: "0xefF2889E38514B160897b5a0338506fEaE0ab0FF",
				initialState: 1000,
				summary: 1000,
				share: "fetch from data 0",
				points: 0,
				origin: 183
			},
			{
				wallet: "0x37E9862af2675527334a2D1fBdD71e609709B9D1",
				initialState: 1000,
				summary: 1000,
				share: "fetch from data 0",
				points: 0,
				origin: 184
			},
			{
				wallet: "0xA394c4fB59aD66f9551F161Faf6E4e43bCF1fdeB",
				initialState: 991,
				summary: 991,
				share: "fetch from data 0",
				points: 0,
				origin: 185
			},
			{
				wallet: "0x6FFfE9DAAb67dfe2395abDa15f69EE1C4bA5d11D",
				initialState: 982,
				summary: 982,
				share: "fetch from data 0",
				points: 0,
				origin: 186
			},
			{
				wallet: "0x5C20F084fa568Ad83f7E9B91624627De042e0287",
				initialState: 957,
				summary: 957,
				share: "fetch from data 0",
				points: 0,
				origin: 187
			},
			{
				wallet: "0x4211ea28CADc7fFdD28f893Df87f963c361b0672",
				initialState: 722,
				summary: 957,
				share: "fetch from data 235",
				points: 235,
				origin: 199
			},
			{
				wallet: "0x824C828798C365543e749ae5F968D84Ec0836cC0",
				initialState: 911,
				summary: 911,
				share: "fetch from data 0",
				points: 0,
				origin: 188
			},
			{
				wallet: "0x7a4510d033986af688a0a8a9ef114d07a7b2a577",
				initialState: 800,
				summary: 902,
				share: "fetch from data 102",
				points: 102,
				origin: 193
			},
			{
				wallet: "0x8bec6acea8072f1e3326d0c1f3558c94e743520e",
				initialState: 101,
				summary: 901,
				share: "fetch from data 800",
				points: 800,
				origin: 278
			},
			{
				wallet: "0xBc9D7626b9EB544feC3f69f90c5DE6Ba5b3b38C1",
				initialState: 895,
				summary: 895,
				share: "fetch from data 0",
				points: 0,
				origin: 190
			},
			{
				wallet: "0x54f3063E8102E21916e595093Ee921b23D8bDe1D",
				initialState: 880,
				summary: 880,
				share: "fetch from data 0",
				points: 0,
				origin: 191
			},
			{
				wallet: "0x37Bf329CD1111dC95C6D806C2Cc3e151a2744215",
				initialState: 24,
				summary: 838,
				share: "fetch from data 814",
				points: 814,
				origin: 298
			},
			{
				wallet: "0xD8A6c9Bc1BD495CfB52576D74ECe333a30589F6b",
				initialState: 445,
				summary: 783,
				share: "fetch from data 338",
				points: 338,
				origin: 227
			},
			{
				wallet: "0x50AF1079587e16C44A56f25BbfE8024Fa336C283",
				initialState: 780,
				summary: 780,
				share: "fetch from data 0",
				points: 0,
				origin: 195
			},
			{
				wallet: "0xf6B8072D49d80C4057B1d66e5cc7D39063F177a4",
				initialState: 726,
				summary: 762,
				share: "fetch from data 36",
				points: 36,
				origin: 198
			},
			{
				wallet: "0xe942F428b0a95c13bD8Be7a7f09000E07dE11f6e",
				initialState: 68,
				summary: 729,
				share: "fetch from data 661",
				points: 661,
				origin: 289
			},
			{
				wallet: "0xD681341e8711867C4fA878A4A5EC8c6768F30430",
				initialState: 700,
				summary: 700,
				share: "fetch from data 0",
				points: 0,
				origin: 201
			},
			{
				wallet: "0x644133da417Df8624101B63a4910727029cf6810",
				initialState: 22,
				summary: 690,
				share: "fetch from data 668",
				points: 668,
				origin: 301
			},
			{
				wallet: "0x776078DC0D3E1C3455BCBe091723491968E52F34",
				initialState: 669,
				summary: 669,
				share: "fetch from data 0",
				points: 0,
				origin: 202
			},
			{
				wallet: "0xeCFab2BDe3eB043F87f3E7e71717A6eBb4e99bFD",
				initialState: 666,
				summary: 666,
				share: "fetch from data 0",
				points: 0,
				origin: 203
			},
			{
				wallet: "0xD90e87D40E86652F1ECBA6efDbF46b179A94880e",
				initialState: 609,
				summary: 661,
				share: "fetch from data 52",
				points: 52,
				origin: 209
			},
			{
				wallet: "0x524C133A9b34728C4B0dBc72C085f2a4222D49d2",
				initialState: 651,
				summary: 651,
				share: "fetch from data 0",
				points: 0,
				origin: 204
			},
			{
				wallet: "0xfd408E80618aD37824191056025be7406E3d68B0",
				initialState: 650,
				summary: 650,
				share: "fetch from data 0",
				points: 0,
				origin: 205
			},
			{
				wallet: "0x10dDc7d3E3eb3406FA6973b77937Aa976dc2dfFE",
				initialState: 648,
				summary: 648,
				share: "fetch from data 0",
				points: 0,
				origin: 206
			},
			{
				wallet: "0x5C4F8087b97a75bC6A7e8255b7087F59fA1DcC26",
				initialState: 641,
				summary: 641,
				share: "fetch from data 0",
				points: 0,
				origin: 207
			},
			{
				wallet: "0x86f31DFA89D4d0c2A05927C93D1378D9dc0Bbe15",
				initialState: 537,
				summary: 637,
				share: "fetch from data 100",
				points: 100,
				origin: 214
			},
			{
				wallet: "0x86948c0A7A3DE5ed8D6dB7274a21eb6070e530dB",
				initialState: 160,
				summary: 630,
				share: "fetch from data 470",
				points: 470,
				origin: 270
			},
			{
				wallet: "0x5c436da44427f259F89bAA2fFcaA99d551ac9B58",
				initialState: 334,
				summary: 625,
				share: "fetch from data 291",
				points: 291,
				origin: 243
			},
			{
				wallet: "0x5A29e7494Cb71B3FBC2a7a3c9D92234C9C995BA4",
				initialState: 623,
				summary: 623,
				share: "fetch from data 0",
				points: 0,
				origin: 208
			},
			{
				wallet: "0x895e00f3016BCA3D673463ec4bEdE3708a890045",
				initialState: 400,
				summary: 600,
				share: "fetch from data 200",
				points: 200,
				origin: 231
			},
			{
				wallet: "0xcdfa2c5ad7c507ef863cd014b6cdfcaa4af8fa5e",
				initialState: 594,
				summary: 594,
				share: "fetch from data 0",
				points: 0,
				origin: 211
			},
			{
				wallet: "0x928eD03D7b2C59cCf67b0AE9bfadb0EC89bB6582",
				initialState: 485,
				summary: 585,
				share: "fetch from data 100",
				points: 100,
				origin: 221
			},
			{
				wallet: "0xFBfbbc62F7c7cC2151E887e8F07EF4E055DF9c9e",
				initialState: 571,
				summary: 571,
				share: "fetch from data 0",
				points: 0,
				origin: 212
			},
			{
				wallet: "0xaed5a80A107C100df6293F2Bf8273d5C60D1f3B6",
				initialState: 564,
				summary: 564,
				share: "fetch from data 0",
				points: 0,
				origin: 213
			},
			{
				wallet: "0xa73fe17a249c0A8ae702e66Fa58AF2eE8793E75b",
				initialState: 433,
				summary: 538,
				share: "fetch from data 105",
				points: 105,
				origin: 228
			},
			{
				wallet: "0xD8dA39A4c0801fB906AA19eE80fd5Ac8B647A404",
				initialState: 518,
				summary: 518,
				share: "fetch from data 0",
				points: 0,
				origin: 216
			},
			{
				wallet: "0xc8B7B5A14C8e104B323C82f84F0E4F5827059461",
				initialState: 504,
				summary: 504,
				share: "fetch from data 0",
				points: 0,
				origin: 218
			},
			{
				wallet: "0x4377D05E17bF706c09e5451d09E8FF15959F7E20",
				initialState: 252,
				summary: 504,
				share: "fetch from data 252",
				points: 252,
				origin: 252
			},
			{
				wallet: "0xe107898706AAf0691E4035eC547D1bD382fe1a10",
				initialState: 493,
				summary: 493,
				share: "fetch from data 0",
				points: 0,
				origin: 219
			},
			{
				wallet: "0xDc364211600E9398Adcbd1244b1862e47e812509",
				initialState: 32,
				summary: 488,
				share: "fetch from data 456",
				points: 456,
				origin: 295
			},
			{
				wallet: "0x0c99EEAc687B58A30E7e08b39E40ac605a8EeCFf",
				initialState: 395,
				summary: 482,
				share: "fetch from data 87",
				points: 87,
				origin: 235
			},
			{
				wallet: "0xc6547284d2c7dBDc4bc7a29105B01d62c03b9481",
				initialState: 476,
				summary: 476,
				share: "fetch from data 0",
				points: 0,
				origin: 223
			},
			{
				wallet: "0x57427585B908dA592A2094e193554c7B8A7fb955",
				initialState: 472,
				summary: 472,
				share: "fetch from data 0",
				points: 0,
				origin: 224
			},
			{
				wallet: "0xB9BC3901928D7A12753527cc2C918c344C2A4f8C",
				initialState: 445,
				summary: 445,
				share: "fetch from data 0",
				points: 0,
				origin: 226
			},
			{
				wallet: "0xfc066e02904cF6B81397Ce2DfF50ac40AdD6F707",
				initialState: 300,
				summary: 412,
				share: "fetch from data 112",
				points: 112,
				origin: 246
			},
			{
				wallet: "0x969264F48aC058b8652d8d1C4D3316A5c2832606",
				initialState: 232,
				summary: 406,
				share: "fetch from data 174",
				points: 174,
				origin: 256
			},
			{
				wallet: "0xbD70A0F5733270E9C9893E3892065C7E4fa95b84",
				initialState: 401,
				summary: 401,
				share: "fetch from data 0",
				points: 0,
				origin: 229
			},
			{
				wallet: "0x2B601E1BE4a7B098ebf247061d5cFa963c9d2d87",
				initialState: 400,
				summary: 400,
				share: "fetch from data 0",
				points: 0,
				origin: 230
			},
			{
				wallet: "0xd4D992F9509Efe9b3ca1b7aCFaAB384ebB99e0cd",
				initialState: 400,
				summary: 400,
				share: "fetch from data 0",
				points: 0,
				origin: 232
			},
			{
				wallet: "0xE85D8584753b770E83B3d491AC2b9c3cf4A64aaf",
				initialState: 400,
				summary: 400,
				share: "fetch from data 0",
				points: 0,
				origin: 233
			},
			{
				wallet: "0xEB8ca482eE0F547e9e3bE84c36A3aE014e49ea99",
				initialState: 400,
				summary: 400,
				share: "fetch from data 0",
				points: 0,
				origin: 234
			},
			{
				wallet: "0xb831dD8fB640Ee5aED49568cEF824aFBC2aEE730",
				initialState: 383,
				summary: 383,
				share: "fetch from data 0",
				points: 0,
				origin: 236
			},
			{
				wallet: "0x25C924089f64D1c25F0FA0A52b368d7A4B09aB36",
				initialState: 376,
				summary: 376,
				share: "fetch from data 0",
				points: 0,
				origin: 237
			},
			{
				wallet: "0xc8b269fcAFa4F86156c4C280603c5E23f182fF0a",
				initialState: 373,
				summary: 373,
				share: "fetch from data 0",
				points: 0,
				origin: 238
			},
			{
				wallet: "0xF3D756A3431182eE75e9e41652e21dA76f2d97e0",
				initialState: 363,
				summary: 363,
				share: "fetch from data 0",
				points: 0,
				origin: 239
			},
			{
				wallet: "0x9fE02ed32444a66e30540FCbC90f37B965362aEB",
				initialState: 361,
				summary: 361,
				share: "fetch from data 0",
				points: 0,
				origin: 240
			},
			{
				wallet: "0xcd67218C1B3B09754A1Eccb95F70B7bdaBF80c94",
				initialState: 246,
				summary: 356,
				share: "fetch from data 110",
				points: 110,
				origin: 254
			},
			{
				wallet: "0xB71F7D7F7D71eE6FB50Db8E8a9Bb1A18203F7349",
				initialState: 355,
				summary: 355,
				share: "fetch from data 0",
				points: 0,
				origin: 241
			},
			{
				wallet: "0x0452ea1eFF2c187F7b4a7bBaf2729d1D1578ca52",
				initialState: 350,
				summary: 350,
				share: "fetch from data 0",
				points: 0,
				origin: 242
			},
			{
				wallet: "0xfb47e2e52E6df63e6490A2D3dA89819084a53dB6",
				initialState: 316,
				summary: 316,
				share: "fetch from data 0",
				points: 0,
				origin: 244
			},
			{
				wallet: "0x961114A92B03Bd90060F91E3cD0C24EFd0ed8F56",
				initialState: 298,
				summary: 298,
				share: "fetch from data 0",
				points: 0,
				origin: 247
			},
			{
				wallet: "0x3e0088B6722EC1F892FEBcABB5AF26d88d977d25",
				initialState: 186,
				summary: 287,
				share: "fetch from data 101",
				points: 101,
				origin: 263
			},
			{
				wallet: "0xD05cA3c955Ac37c2b7135a0a03c0eDcC2906dE07",
				initialState: 280,
				summary: 280,
				share: "fetch from data 0",
				points: 0,
				origin: 248
			},
			{
				wallet: "0xD3ccF68fDa8777b628E57fc02E2e54b3Dcb551F5",
				initialState: 271,
				summary: 271,
				share: "fetch from data 0",
				points: 0,
				origin: 249
			},
			{
				wallet: "0x4E1F9adc536b55623954223aAF4e23CDf7CC2d9e",
				initialState: 267,
				summary: 267,
				share: "fetch from data 0",
				points: 0,
				origin: 250
			},
			{
				wallet: "0xaB88f392CB37446DB0ED4f95eD73e70F8e03bC2B",
				initialState: 265,
				summary: 265,
				share: "fetch from data 0",
				points: 0,
				origin: 251
			},
			{
				wallet: "0xbe9cFd199Af2e3203a9740BE0a5840D07B2F6042",
				initialState: 246,
				summary: 246,
				share: "fetch from data 0",
				points: 0,
				origin: 253
			},
			{
				wallet: "0x088E901C8c94111612ae7bdec41872ec328b77E0",
				initialState: 160,
				summary: 240,
				share: "fetch from data 80",
				points: 80,
				origin: 269
			},
			{
				wallet: "0x04C76F25148F89Df961be0e1c6e562365c8Ec432",
				initialState: 234,
				summary: 234,
				share: "fetch from data 0",
				points: 0,
				origin: 255
			},
			{
				wallet: "0xeedc06228b2ea47FD78c58182BfF04CEcB47a9e9",
				initialState: 220,
				summary: 220,
				share: "fetch from data 0",
				points: 0,
				origin: 257
			},
			{
				wallet: "0x360bA5Bf6917e967c2a7BCea18Cc8F8ef6536A07",
				initialState: 200,
				summary: 200,
				share: "fetch from data 0",
				points: 0,
				origin: 258
			},
			{
				wallet: "0x38df5EaB959095F4589686f4D725c7d0a7ba8F75",
				initialState: 200,
				summary: 200,
				share: "fetch from data 0",
				points: 0,
				origin: 259
			},
			{
				wallet: "0x70635102CF3B7D32FE0a1221b412Cb4b172A2b2f",
				initialState: 200,
				summary: 200,
				share: "fetch from data 0",
				points: 0,
				origin: 260
			},
			{
				wallet: "0x7796D7E2F04b2854cB32F52C6014bEe89fD93C18",
				initialState: 200,
				summary: 200,
				share: "fetch from data 0",
				points: 0,
				origin: 261
			},
			{
				wallet: "0xaef4BAA66318f4821402998272678274b6123fcC",
				initialState: 200,
				summary: 200,
				share: "fetch from data 0",
				points: 0,
				origin: 262
			},
			{
				wallet: "0xC01C2B2EbB9B93B2cA7f4a61E9Ac60Ba7750c9E4",
				initialState: 184,
				summary: 184,
				share: "fetch from data 0",
				points: 0,
				origin: 264
			},
			{
				wallet: "0x03884A2013A72C3E3bBF21Fd6756BD6791D153AD",
				initialState: 182,
				summary: 182,
				share: "fetch from data 0",
				points: 0,
				origin: 265
			},
			{
				wallet: "0x0Eac0Ae37774b090EC14C9D4C28499596AD84A11",
				initialState: 181,
				summary: 181,
				share: "fetch from data 0",
				points: 0,
				origin: 266
			},
			{
				wallet: "0x3EC66EbE2E1Bf1e302d048f673933C622A6826fb",
				initialState: 178,
				summary: 178,
				share: "fetch from data 0",
				points: 0,
				origin: 267
			},
			{
				wallet: "0xDEE79D1CA941df0Cb9F34Bc573DcfcF8923eB4E0",
				initialState: 164,
				summary: 164,
				share: "fetch from data 0",
				points: 0,
				origin: 268
			},
			{
				wallet: "0x334A1831219782c3DCdb905f8575bF27d1b76C9D",
				initialState: 157,
				summary: 157,
				share: "fetch from data 0",
				points: 0,
				origin: 272
			},
			{
				wallet: "0xA7556c667B7cf7A1Ab2767CBC2370ca2fb73627e",
				initialState: 152,
				summary: 152,
				share: "fetch from data 0",
				points: 0,
				origin: 273
			},
			{
				wallet: "0x6feAA0A241ad481EA297c7fD42EbA1fb7A694C31",
				initialState: 152,
				summary: 152,
				share: "fetch from data 0",
				points: 0,
				origin: 274
			},
			{
				wallet: "0x9C69B5437E261323bdd5B32f4d15aA3f9F00BC3a",
				initialState: 73,
				summary: 131,
				share: "fetch from data 58",
				points: 58,
				origin: 287
			},
			{
				wallet: "0xC3408d1Ce37326D9A42EF35842a66B6947FCeA38",
				initialState: 125,
				summary: 125,
				share: "fetch from data 0",
				points: 0,
				origin: 275
			},
			{
				wallet: "0x0cFa310e2365FbAF4852cfF85dceC5549DD5cb65",
				initialState: 117,
				summary: 117,
				share: "fetch from data 0",
				points: 0,
				origin: 276
			},
			{
				wallet: "0x2F670Cef2aC736D1015c53207B81d390382e891c",
				initialState: 117,
				summary: 117,
				share: "fetch from data 0",
				points: 0,
				origin: 277
			},
			{
				wallet: "0xf4C46B1c36756a47F50444957b07231335fD1C78",
				initialState: 58,
				summary: 117,
				share: "fetch from data 59",
				points: 59,
				origin: 290
			},
			{
				wallet: "0xC83af323925c55680fdeDD3EF4f766390ac240ae",
				initialState: 100,
				summary: 100,
				share: "fetch from data 0",
				points: 0,
				origin: 280
			},
			{
				wallet: "0xedb4Ec2E401B984264b4bf8B7DD62a9ec11Ee37A",
				initialState: 100,
				summary: 100,
				share: "fetch from data 0",
				points: 0,
				origin: 281
			},
			{
				wallet: "0x223450159B0674DBe2E44FBFCB0Cab056D2517ED",
				initialState: 87,
				summary: 87,
				share: "fetch from data 0",
				points: 0,
				origin: 283
			},
			{
				wallet: "0xFd256b1d16A5CCb442132fab093EF54524513770",
				initialState: 87,
				summary: 87,
				share: "fetch from data 0",
				points: 0,
				origin: 284
			},
			{
				wallet: "0x08DFBccA7bD77D1ebb62107b923D48a07b117742",
				initialState: 82,
				summary: 82,
				share: "fetch from data 0",
				points: 0,
				origin: 285
			},
			{
				wallet: "0x782f888A9EA1b53b341dfca997C0951933997DaA",
				initialState: 79,
				summary: 79,
				share: "fetch from data 0",
				points: 0,
				origin: 286
			},
			{
				wallet: "0x1cC441f5827B69Bb0a4BF61156b7d1EA473B234d",
				initialState: 72,
				summary: 72,
				share: "fetch from data 0",
				points: 0,
				origin: 288
			},
			{
				wallet: "0x71B2A0b2dd37a7B588ECC5352AAf16b1dD650326",
				initialState: 53,
				summary: 53,
				share: "fetch from data 0",
				points: 0,
				origin: 291
			},
			{
				wallet: "0x35732689A360BE37059f82C4dEde0Fa70Eba52AD",
				initialState: 48,
				summary: 48,
				share: "fetch from data 0",
				points: 0,
				origin: 292
			},
			{
				wallet: "0x21f836C7815B001EEcd0c049529c92ff953dFD05",
				initialState: 46,
				summary: 46,
				share: "fetch from data 0",
				points: 0,
				origin: 293
			},
			{
				wallet: "0xcE7d2f5526F89f89c0927b2e0EF7633581435e53",
				initialState: 40,
				summary: 40,
				share: "fetch from data 0",
				points: 0,
				origin: 294
			},
			{
				wallet: "0x22d14BF9fa7a36F514CBC7b21EE36623983938d4",
				initialState: 31,
				summary: 31,
				share: "fetch from data 0",
				points: 0,
				origin: 296
			},
			{
				wallet: "0x81Fb8E022c6e402eBB7d342ACC94c2c7969bC0b1",
				initialState: 27,
				summary: 27,
				share: "fetch from data 0",
				points: 0,
				origin: 297
			},
			{
				wallet: "0x44805366130d747A062f1dB7119324229d1F5A01",
				initialState: 23,
				summary: 23,
				share: "fetch from data 0",
				points: 0,
				origin: 299
			},
			{
				wallet: "0xf093210DA74a3FCc6aA5CF611C43429Fc812BBba",
				initialState: 23,
				summary: 23,
				share: "fetch from data 0",
				points: 0,
				origin: 300
			},
			{
				wallet: "0xC4C77D94661DBBde746aAf585c3e8f3109f8b6a0",
				initialState: 21,
				summary: 21,
				share: "fetch from data 0",
				points: 0,
				origin: 302
			},
			{
				wallet: "0x4D4b5acE61414494dbcC4d2c3484D2bf92658A01",
				initialState: 16,
				summary: 16,
				share: "fetch from data 0",
				points: 0,
				origin: 303
			},
			{
				wallet: "0x47f782f4c932b4052f7BFa34eBF9B32883e6b921",
				initialState: 15,
				summary: 15,
				share: "fetch from data 0",
				points: 0,
				origin: 304
			}
		]
	},
	new: {
		date: "2024-02-13T13:00:20.405Z",
		table: [
			{
				wallet: "0xc5BD540488dBF76e5D175eAe6eE91370e9e2896b",
				initialState: 639643,
				summary: 645248,
				share: "fetch from data 5605",
				points: 5605,
				origin: 0
			},
			{
				wallet: "0x8E7e1Eb798C4757667AcbB716eB641eD3eB3e18F",
				initialState: 428123,
				summary: 435238,
				share: "fetch from data 7115",
				points: 7115,
				origin: 1
			},
			{
				wallet: "0xD842dACB97a131C0c2C7f2F5f4AF5D49BbA6E2A8",
				initialState: 214874,
				summary: 221227,
				share: "fetch from data 6353",
				points: 6353,
				origin: 2
			},
			{
				wallet: "0xE559F80Da408d5E08f42004C0Ae143D6456f3708",
				initialState: 174607,
				summary: 189607,
				share: "fetch from data 15000",
				points: 15000,
				origin: 3
			},
			{
				wallet: "0x797702f79F248DAf949eb409Fec179A0b2737AE9",
				initialState: 146114,
				summary: 146114,
				share: "fetch from data 0",
				points: 0,
				origin: 4
			},
			{
				wallet: "0x00f49f11Fd49E9d1c2576a7da3809fE5F56B2669",
				initialState: 138615,
				summary: 139817,
				share: "fetch from data 1202",
				points: 1202,
				origin: 5
			},
			{
				wallet: "0xb8320E68Bc588ffcFcDbb13d85B61d7AC1735c5A",
				initialState: 136998,
				summary: 139810,
				share: "fetch from data 2812",
				points: 2812,
				origin: 6
			},
			{
				wallet: "0xCcf8Cfa0448726Aade46df1bb0C143Ad54c2675B",
				initialState: 126474,
				summary: 128866,
				share: "fetch from data 2392",
				points: 2392,
				origin: 7
			},
			{
				wallet: "0x99588d21D07FA23B22685E42B1E47c8bCa551093",
				initialState: 107645,
				summary: 111285,
				share: "fetch from data 3640",
				points: 3640,
				origin: 8
			},
			{
				wallet: "0x81EE4B73d0d7876aeaeAaF8CFd7Fc5FF49F8e861",
				initialState: 88735,
				summary: 88775,
				share: "fetch from data 40",
				points: 40,
				origin: 9
			},
			{
				wallet: "0x8297b1c7d61d71d1Bc077079A63635fbf37c388E",
				initialState: 72919,
				summary: 81557,
				share: "fetch from data 8638",
				points: 8638,
				origin: 10
			},
			{
				wallet: "0xD486B7d8969DB80e2A90A4639e477513575Aa5d9",
				initialState: 55375,
				summary: 70375,
				share: "fetch from data 15000",
				points: 15000,
				origin: 15
			},
			{
				wallet: "0x026709ec865a7cfe1c4273eccfc6b180729cf0eb",
				initialState: 65489,
				summary: 67960,
				share: "fetch from data 2471.0000000000005",
				points: 2471,
				origin: 12
			},
			{
				wallet: "0xb69B2222B0Da0fE03b4096fa6BA4f15Bfa4B3e30",
				initialState: 66262,
				summary: 66542,
				share: "fetch from data 280",
				points: 280,
				origin: 11
			},
			{
				wallet: "0x78c20cD7C8451a7617cf2C8095fF1a890662ec4a",
				initialState: 60815,
				summary: 65915,
				share: "fetch from data 5100",
				points: 5100,
				origin: 14
			},
			{
				wallet: "0x0fab269Ce2bF345A79DA5713f4631E344aCCc44f",
				initialState: 63212,
				summary: 63383,
				share: "fetch from data 171",
				points: 171,
				origin: 13
			},
			{
				wallet: "0x2f184902bc9ae442250613c9d2d044e7f6b6cfb7",
				initialState: 50174,
				summary: 50974,
				share: "fetch from data 800",
				points: 800,
				origin: 16
			},
			{
				wallet: "0xC31Cb557175bdE9645C991520aDf4dE194dA4f09",
				initialState: 49231,
				summary: 49321,
				share: "fetch from data 90",
				points: 90,
				origin: 18
			},
			{
				wallet: "0x9E3111685bf7e9B97a767C4a4bE4E664A01a74Ac",
				initialState: 49289,
				summary: 49289,
				share: "fetch from data 0",
				points: 0,
				origin: 17
			},
			{
				wallet: "0xe5988b25f596c0d7c2ecec5faebeed164f697680",
				initialState: 47392,
				summary: 47392,
				share: "fetch from data 0",
				points: 0,
				origin: 19
			},
			{
				wallet: "0x2A19f9A1B943d4F3730Ebeb4a9AB256D969e5b3F",
				initialState: 42561,
				summary: 42775,
				share: "fetch from data 214",
				points: 214,
				origin: 21
			},
			{
				wallet: "0xB9aaAe94279c28aaF5bdc58c35FaB88b70C9D9B2",
				initialState: 42647,
				summary: 42647,
				share: "fetch from data 0",
				points: 0,
				origin: 20
			},
			{
				wallet: "0x5ae6E2bde3EE2ad6FCfdBff2f19f97a1C54Ca56B",
				initialState: 40562,
				summary: 41362,
				share: "fetch from data 800",
				points: 800,
				origin: 22
			},
			{
				wallet: "0x99908ED8bbdd0d4302D975f539Dac5eC876B6730",
				initialState: 36404,
				summary: 36404,
				share: "fetch from data 0",
				points: 0,
				origin: 23
			},
			{
				wallet: "0x09571339AA35d79190553a8C9af6388cb1C0DF0b",
				initialState: 30264,
				summary: 36244,
				share: "fetch from data 5980",
				points: 5980,
				origin: 27
			},
			{
				wallet: "0x54a576bd48a48ef65f95edfe8173dcaa9415fe9e",
				initialState: 29328,
				summary: 36080,
				share: "fetch from data 6752",
				points: 6752,
				origin: 28
			},
			{
				wallet: "0x7bF2426C652Db59Ef7f1bB31f5DA118c2d3DB172",
				initialState: 35711,
				summary: 35711,
				share: "fetch from data 0",
				points: 0,
				origin: 24
			},
			{
				wallet: "0xc953744F699cd24f40173939190698B88F4bBb41",
				initialState: 34651,
				summary: 35471,
				share: "fetch from data 820",
				points: 820,
				origin: 25
			},
			{
				wallet: "0x3C86339862BD1139d461B0D3030312499707a5b2",
				initialState: 34589,
				summary: 34685,
				share: "fetch from data 96",
				points: 96,
				origin: 26
			},
			{
				wallet: "0xf19D1eF339455F143EF5617cf311A1FA0E52274a",
				initialState: 28759,
				summary: 29059,
				share: "fetch from data 300",
				points: 300,
				origin: 29
			},
			{
				wallet: "0x4A180E3964f80c4C2Ee540E0a029E308Cc2cdB5A",
				initialState: 21327,
				summary: 28399,
				share: "fetch from data 7072",
				points: 7072,
				origin: 33
			},
			{
				wallet: "0xf8131548aa0871d842647152c08040d984d9fe00",
				initialState: 26204,
				summary: 26204,
				share: "fetch from data 0",
				points: 0,
				origin: 30
			},
			{
				wallet: "0xBa7543Df19d6c207B9eC067F0146Dc88Ca3cFD9D",
				initialState: 23119,
				summary: 23919,
				share: "fetch from data 800",
				points: 800,
				origin: 31
			},
			{
				wallet: "0x586326749D35C7a55da421AcE7A6B2Cb871d366E",
				initialState: 18530,
				summary: 22737,
				share: "fetch from data 4207",
				points: 4207,
				origin: 35
			},
			{
				wallet: "0x5f3a73Ac616bAdc60B8a65Df0e5C20C5F5B605d4",
				initialState: 22427,
				summary: 22434,
				share: "fetch from data 7",
				points: 7,
				origin: 32
			},
			{
				wallet: "0x3214f505e554e1f2eb3d8928423ae20466d0acb7",
				initialState: 16353,
				summary: 19233,
				share: "fetch from data 2880",
				points: 2880,
				origin: 42
			},
			{
				wallet: "0xDcff9206A96358c451736Ab34f96b02973EF7b54",
				initialState: 19045,
				summary: 19045,
				share: "fetch from data 0",
				points: 0,
				origin: 34
			},
			{
				wallet: "0x3EdA28f6f210C269180b5ee8B3138C751796286E",
				initialState: 18276,
				summary: 18276,
				share: "fetch from data 0",
				points: 0,
				origin: 36
			},
			{
				wallet: "0x77444A92c8294d40a097DF15A12E62Bb4BFA820C",
				initialState: 18083,
				summary: 18083,
				share: "fetch from data 0",
				points: 0,
				origin: 37
			},
			{
				wallet: "0x3dbC70570aF48e706AA9723313d4De9971102981",
				initialState: 17378,
				summary: 17783,
				share: "fetch from data 405",
				points: 405,
				origin: 39
			},
			{
				wallet: "0x20E6aCD7b99e1EA3231f617D52802d6F751084c7",
				initialState: 17485,
				summary: 17485,
				share: "fetch from data 0",
				points: 0,
				origin: 38
			},
			{
				wallet: "0x4c6De86AE62DAb4296126Ce6CD34Efb21E18427B",
				initialState: 16844,
				summary: 16844,
				share: "fetch from data 0",
				points: 0,
				origin: 40
			},
			{
				wallet: "0x56dC05030AdE425Ee58E310776EA46e785Eb2203",
				initialState: 16602,
				summary: 16604,
				share: "fetch from data 2",
				points: 2,
				origin: 41
			},
			{
				wallet: "0xd26Ad5b02538975efB420f113e17Ab96D3EDCc2a",
				initialState: 16172,
				summary: 16472,
				share: "fetch from data 300",
				points: 300,
				origin: 43
			},
			{
				wallet: "0xF86AEaabD093E817fF8e866083e93d18f07DFa6E",
				initialState: 11831,
				summary: 16424,
				share: "fetch from data 4593",
				points: 4593,
				origin: 54
			},
			{
				wallet: "0x2aca26310D06B16e4b1614D21Ea490633dE80145",
				initialState: 15467,
				summary: 15480,
				share: "fetch from data 13",
				points: 13,
				origin: 44
			},
			{
				wallet: "0x29D6ee592c6A9935E7e9D53fA44bD1eFaD44d30a",
				initialState: 13991,
				summary: 14361,
				share: "fetch from data 370",
				points: 370,
				origin: 46
			},
			{
				wallet: "0x1F2C87b25830C710a56B2aA7035342d99BC1cBbA",
				initialState: 14194,
				summary: 14194,
				share: "fetch from data 0",
				points: 0,
				origin: 45
			},
			{
				wallet: "0x8f53615891001b21cf2b9aA16b7B445Df50F2cF2",
				initialState: 12842,
				summary: 14072,
				share: "fetch from data 1230",
				points: 1230,
				origin: 50
			},
			{
				wallet: "0xa0D843Ca1C8AAF89F7510aeFc432D303a5661c1C",
				initialState: 13889,
				summary: 13889,
				share: "fetch from data 0",
				points: 0,
				origin: 47
			},
			{
				wallet: "0x0F587435fd933646aD250CeD9587169513a07306",
				initialState: 13000,
				summary: 13827,
				share: "fetch from data 827",
				points: 827,
				origin: 48
			},
			{
				wallet: "0xD16be7646F99a9Ae229370c9E50c1FCd800b518d",
				initialState: 12918,
				summary: 13698,
				share: "fetch from data 780",
				points: 780,
				origin: 49
			},
			{
				wallet: "0xf6Ab6Cf23B2C192aEb401cD2DBafF528c8DEF8a1",
				initialState: 12758,
				summary: 12792,
				share: "fetch from data 34",
				points: 34,
				origin: 51
			},
			{
				wallet: "0xe44658fdE9b3b9714382eDCB32D86d48Feca4DD9",
				initialState: 12518,
				summary: 12520,
				share: "fetch from data 2",
				points: 2,
				origin: 52
			},
			{
				wallet: "0x1d33979AD9A3367d5119acd96AF0eA22606D2230",
				initialState: 10603,
				summary: 12081,
				share: "fetch from data 1478",
				points: 1478,
				origin: 57
			},
			{
				wallet: "0xABDc83555B28c30604F74dC200b601175B8C0504",
				initialState: 11942,
				summary: 11942,
				share: "fetch from data 0",
				points: 0,
				origin: 53
			},
			{
				wallet: "0xFC88a072ceFcf9D6E29a28dA0dce56C818486dA7",
				initialState: 10614,
				summary: 11746,
				share: "fetch from data 1132",
				points: 1132,
				origin: 56
			},
			{
				wallet: "0x1606acae9e1e13d4fcd9677016f6aefd9ad7a496",
				initialState: 11308,
				summary: 11308,
				share: "fetch from data 0",
				points: 0,
				origin: 55
			},
			{
				wallet: "0x367A9EEe3a430F8aE7fc056BbE3ECA604653569C",
				initialState: 8891,
				summary: 10700,
				share: "fetch from data 1809",
				points: 1809,
				origin: 63
			},
			{
				wallet: "0x7F2caA45F27D6129379f8F38485D21DF67F863F2",
				initialState: 10518,
				summary: 10518,
				share: "fetch from data 0",
				points: 0,
				origin: 58
			},
			{
				wallet: "0x8737dd01A5f8C4766E742C9E00C943c14Cd3cD66",
				initialState: 8217,
				summary: 10367,
				share: "fetch from data 2150",
				points: 2150,
				origin: 70
			},
			{
				wallet: "0xb3a90f379eA9fa8F7747ceDb95a6520140eF21f7",
				initialState: 10000,
				summary: 10000,
				share: "fetch from data 0",
				points: 0,
				origin: 59
			},
			{
				wallet: "0xdde733b93c63a777b9ff1de617bbf54db16410d0",
				initialState: 10000,
				summary: 10000,
				share: "fetch from data 0",
				points: 0,
				origin: 60
			},
			{
				wallet: "0x97698c4F2e6a7b1eB7daA6a21d4B7B3D90C59Bc6",
				initialState: 9950,
				summary: 9950,
				share: "fetch from data 0",
				points: 0,
				origin: 61
			},
			{
				wallet: "0xDdcB84CDC2f84C418740608D52c3d00DEc121577",
				initialState: 3941,
				summary: 9776,
				share: "fetch from data 5835",
				points: 5835,
				origin: 101
			},
			{
				wallet: "0x4D740ffdB50FEf24865ecaA326bbb1Cc351DE859",
				initialState: 9175,
				summary: 9741,
				share: "fetch from data 566",
				points: 566,
				origin: 62
			},
			{
				wallet: "0x3641E7897b48AF17369dc83aB7779a7430C475AE",
				initialState: 8649,
				summary: 9249,
				share: "fetch from data 600",
				points: 600,
				origin: 65
			},
			{
				wallet: "0xE8C5eCad69CD798A6AfF5c9F10fb668351EEe3aF",
				initialState: 8845,
				summary: 8845,
				share: "fetch from data 0",
				points: 0,
				origin: 64
			},
			{
				wallet: "0xe6351265192C6eF26Fa516144a051C651f35f66c",
				initialState: 8637,
				summary: 8637,
				share: "fetch from data 0",
				points: 0,
				origin: 66
			},
			{
				wallet: "0xC6A1dF806552d1d21E7e54065aD507b5A9FceE49",
				initialState: 8542,
				summary: 8542,
				share: "fetch from data 0",
				points: 0,
				origin: 67
			},
			{
				wallet: "0xd819a67EEe457bd0b2BD77da918f205b483caf2c",
				initialState: 8536,
				summary: 8536,
				share: "fetch from data 0",
				points: 0,
				origin: 68
			},
			{
				wallet: "0xf38b09737b41D08aBAD2D8a37264a8Cb6c05A710",
				initialState: 8473,
				summary: 8473,
				share: "fetch from data 0",
				points: 0,
				origin: 69
			},
			{
				wallet: "0x94001519b1cFcAc6A77841778Fd58599C75dB1cE",
				initialState: 8204,
				summary: 8204,
				share: "fetch from data 0",
				points: 0,
				origin: 71
			},
			{
				wallet: "0x6e83A89e0C52330098967547c05aDDAd13D8616C",
				initialState: 7966,
				summary: 7966,
				share: "fetch from data 0",
				points: 0,
				origin: 72
			},
			{
				wallet: "0x5a168ccbC4754fbAF66a2F1d5d3e28acf45a542b",
				initialState: 6651,
				summary: 7929,
				share: "fetch from data 1278",
				points: 1278,
				origin: 79
			},
			{
				wallet: "0x44b4397b4bF07e8F9CC822aCaA699dB116F66496",
				initialState: 7624,
				summary: 7883,
				share: "fetch from data 259",
				points: 259,
				origin: 73
			},
			{
				wallet: "0x149f06b4bDEB1E8c4C09F4619c4485f395aAA081",
				initialState: 7523,
				summary: 7644,
				share: "fetch from data 121",
				points: 121,
				origin: 74
			},
			{
				wallet: "0xf56c7EBF73203B89A107eB8aE951D5433E3dc307",
				initialState: 3065,
				summary: 7610,
				share: "fetch from data 4545",
				points: 4545,
				origin: 119
			},
			{
				wallet: "0xB0DE5547DB48bDacF0596000718956A700197453",
				initialState: 6806,
				summary: 7285,
				share: "fetch from data 479",
				points: 479,
				origin: 77
			},
			{
				wallet: "0xA5b4d58F69774A7eEA5A17cbf0B685cB0921aa11",
				initialState: 7212,
				summary: 7212,
				share: "fetch from data 0",
				points: 0,
				origin: 75
			},
			{
				wallet: "0xb5A75c4A40F10B68A614A7aDFf23623f47b317fC",
				initialState: 7059,
				summary: 7059,
				share: "fetch from data 0",
				points: 0,
				origin: 76
			},
			{
				wallet: "0x7774643697FA3D44c388454BBFB982C91d6ed405",
				initialState: 2929,
				summary: 7005,
				share: "fetch from data 4076",
				points: 4076,
				origin: 123
			},
			{
				wallet: "0x1C940Ae97bbeF2F9A20d079EB9AE6694a512f57b",
				initialState: 2188,
				summary: 6733,
				share: "fetch from data 4545",
				points: 4545,
				origin: 140
			},
			{
				wallet: "0x18912511C6Aed6223e497BA7Ceaa02EF3c398cD7",
				initialState: 6676,
				summary: 6676,
				share: "fetch from data 0",
				points: 0,
				origin: 78
			},
			{
				wallet: "0x7add8DD4A8719391564377b608AD574dA5CC501a",
				initialState: 6646,
				summary: 6646,
				share: "fetch from data 0",
				points: 0,
				origin: 80
			},
			{
				wallet: "0x1e9F7bf95Fa16834B1189533855d5403d4A596B6",
				initialState: 6616,
				summary: 6616,
				share: "fetch from data 0",
				points: 0,
				origin: 81
			},
			{
				wallet: "0x8ecAcB6Ba16389524cCAF26D599632dF22C32FE7",
				initialState: 4356,
				summary: 6420,
				share: "fetch from data 2064",
				points: 2064,
				origin: 96
			},
			{
				wallet: "0x5424dF78c8C69eCC53D0713DBA36E882930b4b03",
				initialState: 6106,
				summary: 6206,
				share: "fetch from data 100",
				points: 100,
				origin: 83
			},
			{
				wallet: "0x100fA3EE7C0fB22cA87EAD78292b071D2c5b3708",
				initialState: 3076,
				summary: 6186,
				share: "fetch from data 3110",
				points: 3110,
				origin: 118
			},
			{
				wallet: "0x3e75edc6b4AE5668f824A44044a44bf53275Dbf9",
				initialState: 6176,
				summary: 6176,
				share: "fetch from data 0",
				points: 0,
				origin: 82
			},
			{
				wallet: "0x650409019Fafa08E89cFED870eD3D084e9b5918d",
				initialState: 5450,
				summary: 6049,
				share: "fetch from data 599",
				points: 599,
				origin: 88
			},
			{
				wallet: "0xca90808fd811154c7a116e5f6ebf2062f292213a",
				initialState: 5827,
				summary: 6047,
				share: "fetch from data 220",
				points: 220,
				origin: 86
			},
			{
				wallet: "0x0F6722d52b88C01Dcc33a12A8102E5c162099e85",
				initialState: 6004,
				summary: 6004,
				share: "fetch from data 0",
				points: 0,
				origin: 84
			},
			{
				wallet: "0x015ef705d45326ad9ce55f0aaacef8d4e2ea3355",
				initialState: 6000,
				summary: 6000,
				share: "fetch from data 0",
				points: 0,
				origin: 85
			},
			{
				wallet: "0x834dD65b0a6A74EdAB260eC705F4857640e54191",
				initialState: 1882,
				summary: 5633,
				share: "fetch from data 3751.0000000000005",
				points: 3751,
				origin: 150
			},
			{
				wallet: "0x6A94d0f10b10A3fe04f8fDf9cAD7B0E08C5bc154",
				initialState: 5616,
				summary: 5616,
				share: "fetch from data 0",
				points: 0,
				origin: 87
			},
			{
				wallet: "0xAa13Ee09e4D2eA02E07924b2ba0a75A3f61b66C9",
				initialState: 3709,
				summary: 5502,
				share: "fetch from data 1793",
				points: 1793,
				origin: 107
			},
			{
				wallet: "0x0C7eb7E6C93FAC81Db9302eb0e4730067a570DDD",
				initialState: 4418,
				summary: 5430,
				share: "fetch from data 1012",
				points: 1012,
				origin: 95
			},
			{
				wallet: "0x9ceB39629389BD726F26cC20363D79AF50B0641d",
				initialState: 4932,
				summary: 5429,
				share: "fetch from data 497",
				points: 497,
				origin: 92
			},
			{
				wallet: "0x6151420D76114dd8B8613391014D59964C9Bc8d8",
				initialState: 5327,
				summary: 5349,
				share: "fetch from data 22",
				points: 22,
				origin: 89
			},
			{
				wallet: "0x752D707f95C115dA3819a6Ad20e11555BcdaE160",
				initialState: 5300,
				summary: 5300,
				share: "fetch from data 0",
				points: 0,
				origin: 90
			},
			{
				wallet: "0x62a3aAf79967A4b5491c8D272340E1545Dc2d769",
				initialState: 5011,
				summary: 5011,
				share: "fetch from data 0",
				points: 0,
				origin: 91
			},
			{
				wallet: "0xd674098Dc3AD6acC949b7cece902BE21e5C7c395",
				initialState: 3016,
				summary: 4960,
				share: "fetch from data 1944",
				points: 1944,
				origin: 121
			},
			{
				wallet: "0x9D777BBc9Bb82dC7E301dC8e5A55f92932b173E0",
				initialState: 4105,
				summary: 4895,
				share: "fetch from data 790",
				points: 790,
				origin: 99
			},
			{
				wallet: "0x3B9547E1F40B73A9Dc75f645a84A23a594d9F8f4",
				initialState: 3616,
				summary: 4883,
				share: "fetch from data 1267",
				points: 1267,
				origin: 108
			},
			{
				wallet: "0x80994485a70E298e89ee4f6C8A9E70F67Bcd6659",
				initialState: 4700,
				summary: 4700,
				share: "fetch from data 0",
				points: 0,
				origin: 93
			},
			{
				wallet: "0x525aF36d7cF0D7693E0CbB67BF8276792CC328F1",
				initialState: 3923,
				summary: 4504,
				share: "fetch from data 581",
				points: 581,
				origin: 103
			},
			{
				wallet: "0x68c6af90219B9Dd0Da54d4fa585aa78Dd11D8867",
				initialState: 4450,
				summary: 4450,
				share: "fetch from data 0",
				points: 0,
				origin: 94
			},
			{
				wallet: "0xa8413B6Debe69e2B15eEdDa879a4aCF535a1ec70",
				initialState: 4192,
				summary: 4442,
				share: "fetch from data 250",
				points: 250,
				origin: 98
			},
			{
				wallet: "0xb3c6907d27f622Aa7Cd896F3d88442fA5122E6F9",
				initialState: 3984,
				summary: 4388,
				share: "fetch from data 404",
				points: 404,
				origin: 100
			},
			{
				wallet: "0x1B67455A7Ecf1eA253f930233219e7829f0f1F49",
				initialState: 4351,
				summary: 4358,
				share: "fetch from data 7",
				points: 7,
				origin: 97
			},
			{
				wallet: "0xa1C3684E01Fab4083912f065FA9f05d1176F0293",
				initialState: 3204,
				summary: 4119,
				share: "fetch from data 915",
				points: 915,
				origin: 113
			},
			{
				wallet: "0xD443437880957010D574025C80F113c002595f8c",
				initialState: 3152,
				summary: 4019,
				share: "fetch from data 867",
				points: 867,
				origin: 115
			},
			{
				wallet: "0x7943180E055bEf9630a2d7594F9509b8613839EA",
				initialState: 3144,
				summary: 4014,
				share: "fetch from data 870",
				points: 870,
				origin: 117
			},
			{
				wallet: "0xFc5C9bD4444908f535027cc4B39F7287f7654FfC",
				initialState: 3051,
				summary: 3954,
				share: "fetch from data 903",
				points: 903,
				origin: 120
			},
			{
				wallet: "0xb43224dd0cb03bc98b6c743c3173f28b6c53eae3",
				initialState: 3928,
				summary: 3928,
				share: "fetch from data 0",
				points: 0,
				origin: 102
			},
			{
				wallet: "0x643dF7893dBD02f35e127501CF78AB06DfDA7a57",
				initialState: 3550,
				summary: 3906,
				share: "fetch from data 356",
				points: 356,
				origin: 110
			},
			{
				wallet: "0x92cdf2fe974cAA2710Df8Dee6F2C0AD8fC0BdBC9",
				initialState: 2585,
				summary: 3889,
				share: "fetch from data 1304",
				points: 1304,
				origin: 131
			},
			{
				wallet: "0x237fEe61BcBb6562498ca6320AF7F823aA79B3D1",
				initialState: 2097,
				summary: 3864,
				share: "fetch from data 1767",
				points: 1767,
				origin: 143
			},
			{
				wallet: "0xCbd20280cCba4Fa92D8B6057291F329f11fFD2D2",
				initialState: 3857,
				summary: 3857,
				share: "fetch from data 0",
				points: 0,
				origin: 104
			},
			{
				wallet: "0x510cd8afa03ee3dd66ff67bce6ddc5aeaf767f17",
				initialState: 3750,
				summary: 3750,
				share: "fetch from data 0",
				points: 0,
				origin: 105
			},
			{
				wallet: "0xb04619c52a729b6687045012bc206688680289b4",
				initialState: 3735,
				summary: 3735,
				share: "fetch from data 0",
				points: 0,
				origin: 106
			},
			{
				wallet: "0xA28473302CC3F33578be8013AE6fec7DA6B0C2fa",
				initialState: 3585,
				summary: 3585,
				share: "fetch from data 0",
				points: 0,
				origin: 109
			},
			{
				wallet: "0xfbB0Bc831F34864c90fC4bF9d320BBbd08090adF",
				initialState: 2262,
				summary: 3563,
				share: "fetch from data 1301",
				points: 1301,
				origin: 139
			},
			{
				wallet: "0x7199019069830dA0649C2f13C02f69f8A94Edcd8",
				initialState: 2751,
				summary: 3551,
				share: "fetch from data 800",
				points: 800,
				origin: 128
			},
			{
				wallet: "0xb15E6CcC1ca878F4A8627BF4fb69C9A8cCd6dF35",
				initialState: 3549,
				summary: 3549,
				share: "fetch from data 0",
				points: 0,
				origin: 111
			},
			{
				wallet: "0x436379D48fDfB1Bf682C55239E2490851d37a560",
				initialState: 2928,
				summary: 3505,
				share: "fetch from data 577",
				points: 577,
				origin: 124
			},
			{
				wallet: "0x80333B7F0E802822D3f0800175A3626e231a60B3",
				initialState: 2105,
				summary: 3498,
				share: "fetch from data 1393",
				points: 1393,
				origin: 142
			},
			{
				wallet: "0x59992E33ebd79938Cc04D7CFD659d5D3a8a140A0",
				initialState: 3275,
				summary: 3275,
				share: "fetch from data 0",
				points: 0,
				origin: 112
			},
			{
				wallet: "0x422f2975CF01a53892CF359FD3F3dd63033Ae63B",
				initialState: 3153,
				summary: 3258,
				share: "fetch from data 105",
				points: 105,
				origin: 114
			},
			{
				wallet: "0x00Db6d71a9b299ec2fffc5E420850d8E9C338F29",
				initialState: 451,
				summary: 3230,
				share: "fetch from data 2779.0000000000005",
				points: 2779,
				origin: 225
			},
			{
				wallet: "0xA2dd302B997a1543593dc61ED418dB5c5c6Ad458",
				initialState: 3150,
				summary: 3150,
				share: "fetch from data 0",
				points: 0,
				origin: 116
			},
			{
				wallet: "0xE330Ba0Dc85D74B11a4ef50b9F2dDa3A1a12f327",
				initialState: 3007,
				summary: 3097,
				share: "fetch from data 90",
				points: 90,
				origin: 122
			},
			{
				wallet: "0x1b5C00E0b32624B1F0604110d6068aC0Ced3E8b8",
				initialState: 516,
				summary: 3091,
				share: "fetch from data 2575.0000000000005",
				points: 2575,
				origin: 217
			},
			{
				wallet: "0x0626B02Da0B8238Eb6C2A29629aF415D8bd8C1Db",
				initialState: 89,
				summary: 3084,
				share: "fetch from data 2995.0000000000005",
				points: 2995,
				origin: 282
			},
			{
				wallet: "0xc2632DCC9aA2b6DBA67272e5442AB7f9384a8Ba3",
				initialState: 2886,
				summary: 2886,
				share: "fetch from data 0",
				points: 0,
				origin: 125
			},
			{
				wallet: "0x2C6f0b51Fc09676022C7eE38Ab5225c2A451e8d5",
				initialState: 2880,
				summary: 2880,
				share: "fetch from data 0",
				points: 0,
				origin: 126
			},
			{
				wallet: "0xEcf7F18530d656430dBBBb1703596C9ccF2a173E",
				initialState: 2789,
				summary: 2789,
				share: "fetch from data 0",
				points: 0,
				origin: 127
			},
			{
				wallet: "0x9d58d12702054CA448e06cc6c381023E27ee84Ff",
				initialState: 805,
				summary: 2731,
				share: "fetch from data 1926",
				points: 1926,
				origin: 192
			},
			{
				wallet: "0xcDe0C934Ac6c83AC248C5b031F2e9cA582F2354b",
				initialState: 2112,
				summary: 2717,
				share: "fetch from data 605",
				points: 605,
				origin: 141
			},
			{
				wallet: "0xE255b82DE33763956500AF754FB4D26411131FDE",
				initialState: 2600,
				summary: 2670,
				share: "fetch from data 70",
				points: 70,
				origin: 130
			},
			{
				wallet: "0x27B6dCFF8786AbBC517C75Fad20f9b4539Dc35CF",
				initialState: 2650,
				summary: 2650,
				share: "fetch from data 0",
				points: 0,
				origin: 129
			},
			{
				wallet: "0xE39eC2FFF9d73a63F7E3Cba24a1B28FAE29e78Fb",
				initialState: 1196,
				summary: 2619,
				share: "fetch from data 1423",
				points: 1423,
				origin: 171
			},
			{
				wallet: "0xdbcedaa96de3a16e3a94a357837f670bbf416590",
				initialState: 2300,
				summary: 2616,
				share: "fetch from data 316",
				points: 316,
				origin: 138
			},
			{
				wallet: "0x5203D0fDB25836653a59B925772BE504aB7dea7E",
				initialState: 2459,
				summary: 2474,
				share: "fetch from data 15",
				points: 15,
				origin: 132
			},
			{
				wallet: "0x4e34Ee67526Ad466398F124E3774E6127dB98E59",
				initialState: 101,
				summary: 2473,
				share: "fetch from data 2372",
				points: 2372,
				origin: 279
			},
			{
				wallet: "0xFd37e1E4a0bd856CFECA37E1a1e3cef38A2ae195",
				initialState: 2450,
				summary: 2450,
				share: "fetch from data 0",
				points: 0,
				origin: 133
			},
			{
				wallet: "0xe071A0FD88eBEB6AffE2BC6b31002b31499387be",
				initialState: 2431,
				summary: 2431,
				share: "fetch from data 0",
				points: 0,
				origin: 134
			},
			{
				wallet: "0x9Cfa4C3F5084CfA19f21cE037310CbD6354ab127",
				initialState: 2373,
				summary: 2373,
				share: "fetch from data 0",
				points: 0,
				origin: 135
			},
			{
				wallet: "0x6d49929b7CFD62DF922aE6A9DC1F92F3b951D172",
				initialState: 1959,
				summary: 2359,
				share: "fetch from data 400",
				points: 400,
				origin: 147
			},
			{
				wallet: "0xdf5a946C1DD12406D88d0975f6498abBbf26B5BA",
				initialState: 2346,
				summary: 2346,
				share: "fetch from data 0",
				points: 0,
				origin: 136
			},
			{
				wallet: "0x3D240abF4290688007e55A204EFf426E7b67A09a",
				initialState: 2333,
				summary: 2333,
				share: "fetch from data 0",
				points: 0,
				origin: 137
			},
			{
				wallet: "0x81346f7cC179C6a544fD1ED1E90bb53B494121C5",
				initialState: 1474,
				summary: 2274,
				share: "fetch from data 800",
				points: 800,
				origin: 161
			},
			{
				wallet: "0x1B6B795e50F49c5daBcE593cA8EBe3a740FeD53A",
				initialState: 600,
				summary: 2190,
				share: "fetch from data 1590",
				points: 1590,
				origin: 210
			},
			{
				wallet: "0x2dc092B9a3bc6E1D2dEff9BdCABd733298b3e472",
				initialState: 1965,
				summary: 2187,
				share: "fetch from data 222",
				points: 222,
				origin: 146
			},
			{
				wallet: "0x4a606534699E746bF4c384D1d2697358DCB094A6",
				initialState: 1714,
				summary: 2056,
				share: "fetch from data 342",
				points: 342,
				origin: 154
			},
			{
				wallet: "0x3681864E2C887f551B0BA999aa14b27AC586C65D",
				initialState: 2052,
				summary: 2052,
				share: "fetch from data 0",
				points: 0,
				origin: 144
			},
			{
				wallet: "0xaDAF5025EF900D5aa7eF2F8768FB370366673ec8",
				initialState: 2036,
				summary: 2036,
				share: "fetch from data 0",
				points: 0,
				origin: 145
			},
			{
				wallet: "0x5D4D08a8f8710776B1bf89dAAD1AcDc98daeA16D",
				initialState: 1898,
				summary: 1999,
				share: "fetch from data 101",
				points: 101,
				origin: 149
			},
			{
				wallet: "0xf4B22bFEF0f90C57E9b49f7C13d27815F78D1Ba6",
				initialState: 1926,
				summary: 1926,
				share: "fetch from data 0",
				points: 0,
				origin: 148
			},
			{
				wallet: "0xd6dc1f3746bFc92c3C921468612393824daa83e7",
				initialState: 1880,
				summary: 1880,
				share: "fetch from data 0",
				points: 0,
				origin: 151
			},
			{
				wallet: "0x1cF865c35C6c0226a6e98A93703F93a7eD585151",
				initialState: 1242,
				summary: 1872,
				share: "fetch from data 630",
				points: 630,
				origin: 168
			},
			{
				wallet: "0xC98585466c62a81948E17488A0393254965B9986",
				initialState: 1857,
				summary: 1857,
				share: "fetch from data 0",
				points: 0,
				origin: 152
			},
			{
				wallet: "0x73bb7a5449760f65EB5949d5e4E2d0666A609A06",
				initialState: 1000,
				summary: 1800,
				share: "fetch from data 800",
				points: 800,
				origin: 181
			},
			{
				wallet: "0x680288a5D1d7A2288ceE49cbD430Ef6B5F28eFdc",
				initialState: 1781,
				summary: 1781,
				share: "fetch from data 0",
				points: 0,
				origin: 153
			},
			{
				wallet: "0xdb72Db7E973E48cFF5223038c3d45c595BfB42f3",
				initialState: 1052,
				summary: 1739,
				share: "fetch from data 687",
				points: 687,
				origin: 178
			},
			{
				wallet: "0x902802f01Ae23F864b356eD6B80E7000Deb25342",
				initialState: 160,
				summary: 1654,
				share: "fetch from data 1494",
				points: 1494,
				origin: 271
			},
			{
				wallet: "0xeE6De9E9cF0b9F77605BE39244ac488B3863F067",
				initialState: 1616,
				summary: 1616,
				share: "fetch from data 0",
				points: 0,
				origin: 155
			},
			{
				wallet: "0xC3405F9231F35d188c92a25B5706841Df1b016e3",
				initialState: 909,
				summary: 1616,
				share: "fetch from data 707",
				points: 707,
				origin: 189
			},
			{
				wallet: "0x1DB34F232023231f83f1fa53C73A0Bf9627e765A",
				initialState: 1108,
				summary: 1608,
				share: "fetch from data 500",
				points: 500,
				origin: 177
			},
			{
				wallet: "0xd2CBA9B7c27412724cCC5c4F941389ceeC89bE63",
				initialState: 1600,
				summary: 1600,
				share: "fetch from data 0",
				points: 0,
				origin: 156
			},
			{
				wallet: "0x537102197c88b050965e523D508Cd4528Eff5537",
				initialState: 1149,
				summary: 1556,
				share: "fetch from data 407",
				points: 407,
				origin: 174
			},
			{
				wallet: "0x76Ed0D9bb3EA2D498F044DA9D1eC45B66D883127",
				initialState: 1542,
				summary: 1542,
				share: "fetch from data 0",
				points: 0,
				origin: 157
			},
			{
				wallet: "0x45f31d955197e8BBCE3f99a06eA9c46FE07EEefb",
				initialState: 1540,
				summary: 1540,
				share: "fetch from data 0",
				points: 0,
				origin: 158
			},
			{
				wallet: "0x5a4d475DF4d1ec06992364FeFB6Ab0c9Fd502F8c",
				initialState: 1535,
				summary: 1535,
				share: "fetch from data 0",
				points: 0,
				origin: 159
			},
			{
				wallet: "0x76b15fBd87f7D48c28A77BdBC94ab4FaDb546038",
				initialState: 308,
				summary: 1535,
				share: "fetch from data 1227",
				points: 1227,
				origin: 245
			},
			{
				wallet: "0xcf7E658897f871562103B97eefE1F9022C697a57",
				initialState: 1175,
				summary: 1514,
				share: "fetch from data 339",
				points: 339,
				origin: 172
			},
			{
				wallet: "0x0C581AA72E35f786A957d3Abb0D1Ee10a80E2b92",
				initialState: 1501,
				summary: 1501,
				share: "fetch from data 0",
				points: 0,
				origin: 160
			},
			{
				wallet: "0xFCf97B2482F1F06F533f7EBf2b2d4612324dC464",
				initialState: 479,
				summary: 1489,
				share: "fetch from data 1010",
				points: 1010,
				origin: 222
			},
			{
				wallet: "0xdfa7012984B69451af9633ccf31627cCF7BB471C",
				initialState: 1465,
				summary: 1465,
				share: "fetch from data 0",
				points: 0,
				origin: 162
			},
			{
				wallet: "0xB0136825be910fDe99401EB62b7C992d7f9FC243",
				initialState: 519,
				summary: 1437,
				share: "fetch from data 918",
				points: 918,
				origin: 215
			},
			{
				wallet: "0xD86d973Be3F9E41a4Cf94D3Cd853b0427087Bc40",
				initialState: 1390,
				summary: 1390,
				share: "fetch from data 0",
				points: 0,
				origin: 163
			},
			{
				wallet: "0xFbE5f36268E39965A53a70a8648c53A8FDA3f866",
				initialState: 1390,
				summary: 1390,
				share: "fetch from data 0",
				points: 0,
				origin: 164
			},
			{
				wallet: "0xF38D3252EdAf849A936A9aDbD2F5931E5467227d",
				initialState: 8,
				summary: 1389,
				share: "fetch from data 1381",
				points: 1381,
				origin: 305
			},
			{
				wallet: "0x6fAB12c14bA2c632C7142a77E864d188Fbf4d2E2",
				initialState: 1350,
				summary: 1350,
				share: "fetch from data 0",
				points: 0,
				origin: 165
			},
			{
				wallet: "0x6bD23c065CcF4A728A560673eb1748c913C94e99",
				initialState: 1350,
				summary: 1350,
				share: "fetch from data 0",
				points: 0,
				origin: 166
			},
			{
				wallet: "0x9700c58e1c6a1e5fca5db5f62e477eb182fe3da4",
				initialState: 1334,
				summary: 1334,
				share: "fetch from data 0",
				points: 0,
				origin: 167
			},
			{
				wallet: "0x2caac220865b9B3954A2FdA2a3B837c51Cc432b4",
				initialState: 1139,
				summary: 1299,
				share: "fetch from data 160",
				points: 160,
				origin: 175
			},
			{
				wallet: "0x7E850f937a7eE624f2C904A328783f5C34d66881",
				initialState: 487,
				summary: 1249,
				share: "fetch from data 762",
				points: 762,
				origin: 220
			},
			{
				wallet: "0xD0dd9Df01cA8125C821CBA2547BfbD4993007cF1",
				initialState: 1239,
				summary: 1239,
				share: "fetch from data 0",
				points: 0,
				origin: 169
			},
			{
				wallet: "0x70696dDf3DaA2599643c72a533faE7E170c7bea4",
				initialState: 1200,
				summary: 1200,
				share: "fetch from data 0",
				points: 0,
				origin: 170
			},
			{
				wallet: "0x11Fca83a1E3C2Bde1B8b11Bf2140c0703FB59111",
				initialState: 1150,
				summary: 1150,
				share: "fetch from data 0",
				points: 0,
				origin: 173
			},
			{
				wallet: "0x6d6B17C89Ba207C0e71aA421dDAf47D44A54F459",
				initialState: 1121,
				summary: 1121,
				share: "fetch from data 0",
				points: 0,
				origin: 176
			},
			{
				wallet: "0xA286609f7B427fC802012Fca526D87713835abFf",
				initialState: 729,
				summary: 1104,
				share: "fetch from data 375",
				points: 375,
				origin: 197
			},
			{
				wallet: "0x87C1CDB88bE4E10880828Ec289DB46319858a050",
				initialState: 1035,
				summary: 1037,
				share: "fetch from data 2",
				points: 2,
				origin: 179
			},
			{
				wallet: "0x806Be734eE0f89e9B00b384d5E021F7eA4Fc1FEF",
				initialState: 744,
				summary: 1022,
				share: "fetch from data 278",
				points: 278,
				origin: 196
			},
			{
				wallet: "0xA59429AA760e75C22340a5331a889dC11e69feb0",
				initialState: 705,
				summary: 1015,
				share: "fetch from data 310",
				points: 310,
				origin: 200
			},
			{
				wallet: "0xF0626F4CC2D38677cEAD17f041dDe45fe625B85D",
				initialState: 798,
				summary: 1008,
				share: "fetch from data 210",
				points: 210,
				origin: 194
			},
			{
				wallet: "0xc9b03837276B9F224eB5A03ccbB57438d9182E9f",
				initialState: 1007,
				summary: 1007,
				share: "fetch from data 0",
				points: 0,
				origin: 180
			},
			{
				wallet: "0xcF715e62D18ce2d63157cBbA31b905Ac1cC8cF90",
				initialState: 1000,
				summary: 1000,
				share: "fetch from data 0",
				points: 0,
				origin: 182
			},
			{
				wallet: "0xefF2889E38514B160897b5a0338506fEaE0ab0FF",
				initialState: 1000,
				summary: 1000,
				share: "fetch from data 0",
				points: 0,
				origin: 183
			},
			{
				wallet: "0x37E9862af2675527334a2D1fBdD71e609709B9D1",
				initialState: 1000,
				summary: 1000,
				share: "fetch from data 0",
				points: 0,
				origin: 184
			},
			{
				wallet: "0xA394c4fB59aD66f9551F161Faf6E4e43bCF1fdeB",
				initialState: 991,
				summary: 991,
				share: "fetch from data 0",
				points: 0,
				origin: 185
			},
			{
				wallet: "0x6FFfE9DAAb67dfe2395abDa15f69EE1C4bA5d11D",
				initialState: 982,
				summary: 982,
				share: "fetch from data 0",
				points: 0,
				origin: 186
			},
			{
				wallet: "0x5C20F084fa568Ad83f7E9B91624627De042e0287",
				initialState: 957,
				summary: 957,
				share: "fetch from data 0",
				points: 0,
				origin: 187
			},
			{
				wallet: "0x4211ea28CADc7fFdD28f893Df87f963c361b0672",
				initialState: 722,
				summary: 957,
				share: "fetch from data 235",
				points: 235,
				origin: 199
			},
			{
				wallet: "0x824C828798C365543e749ae5F968D84Ec0836cC0",
				initialState: 911,
				summary: 911,
				share: "fetch from data 0",
				points: 0,
				origin: 188
			},
			{
				wallet: "0x7a4510d033986af688a0a8a9ef114d07a7b2a577",
				initialState: 800,
				summary: 902,
				share: "fetch from data 102",
				points: 102,
				origin: 193
			},
			{
				wallet: "0x8bec6acea8072f1e3326d0c1f3558c94e743520e",
				initialState: 101,
				summary: 901,
				share: "fetch from data 800",
				points: 800,
				origin: 278
			},
			{
				wallet: "0xBc9D7626b9EB544feC3f69f90c5DE6Ba5b3b38C1",
				initialState: 895,
				summary: 895,
				share: "fetch from data 0",
				points: 0,
				origin: 190
			},
			{
				wallet: "0x54f3063E8102E21916e595093Ee921b23D8bDe1D",
				initialState: 880,
				summary: 880,
				share: "fetch from data 0",
				points: 0,
				origin: 191
			},
			{
				wallet: "0x37Bf329CD1111dC95C6D806C2Cc3e151a2744215",
				initialState: 24,
				summary: 838,
				share: "fetch from data 814",
				points: 814,
				origin: 298
			},
			{
				wallet: "0xD8A6c9Bc1BD495CfB52576D74ECe333a30589F6b",
				initialState: 445,
				summary: 783,
				share: "fetch from data 338",
				points: 338,
				origin: 227
			},
			{
				wallet: "0x50AF1079587e16C44A56f25BbfE8024Fa336C283",
				initialState: 780,
				summary: 780,
				share: "fetch from data 0",
				points: 0,
				origin: 195
			},
			{
				wallet: "0xf6B8072D49d80C4057B1d66e5cc7D39063F177a4",
				initialState: 726,
				summary: 762,
				share: "fetch from data 36",
				points: 36,
				origin: 198
			},
			{
				wallet: "0xe942F428b0a95c13bD8Be7a7f09000E07dE11f6e",
				initialState: 68,
				summary: 729,
				share: "fetch from data 661",
				points: 661,
				origin: 289
			},
			{
				wallet: "0xD681341e8711867C4fA878A4A5EC8c6768F30430",
				initialState: 700,
				summary: 700,
				share: "fetch from data 0",
				points: 0,
				origin: 201
			},
			{
				wallet: "0x644133da417Df8624101B63a4910727029cf6810",
				initialState: 22,
				summary: 690,
				share: "fetch from data 668",
				points: 668,
				origin: 301
			},
			{
				wallet: "0x776078DC0D3E1C3455BCBe091723491968E52F34",
				initialState: 669,
				summary: 669,
				share: "fetch from data 0",
				points: 0,
				origin: 202
			},
			{
				wallet: "0xeCFab2BDe3eB043F87f3E7e71717A6eBb4e99bFD",
				initialState: 666,
				summary: 666,
				share: "fetch from data 0",
				points: 0,
				origin: 203
			},
			{
				wallet: "0xD90e87D40E86652F1ECBA6efDbF46b179A94880e",
				initialState: 609,
				summary: 661,
				share: "fetch from data 52",
				points: 52,
				origin: 209
			},
			{
				wallet: "0x524C133A9b34728C4B0dBc72C085f2a4222D49d2",
				initialState: 651,
				summary: 651,
				share: "fetch from data 0",
				points: 0,
				origin: 204
			},
			{
				wallet: "0xfd408E80618aD37824191056025be7406E3d68B0",
				initialState: 650,
				summary: 650,
				share: "fetch from data 0",
				points: 0,
				origin: 205
			},
			{
				wallet: "0x10dDc7d3E3eb3406FA6973b77937Aa976dc2dfFE",
				initialState: 648,
				summary: 648,
				share: "fetch from data 0",
				points: 0,
				origin: 206
			},
			{
				wallet: "0x5C4F8087b97a75bC6A7e8255b7087F59fA1DcC26",
				initialState: 641,
				summary: 641,
				share: "fetch from data 0",
				points: 0,
				origin: 207
			},
			{
				wallet: "0x86f31DFA89D4d0c2A05927C93D1378D9dc0Bbe15",
				initialState: 537,
				summary: 637,
				share: "fetch from data 100",
				points: 100,
				origin: 214
			},
			{
				wallet: "0x86948c0A7A3DE5ed8D6dB7274a21eb6070e530dB",
				initialState: 160,
				summary: 630,
				share: "fetch from data 470",
				points: 470,
				origin: 270
			},
			{
				wallet: "0x5c436da44427f259F89bAA2fFcaA99d551ac9B58",
				initialState: 334,
				summary: 625,
				share: "fetch from data 291",
				points: 291,
				origin: 243
			},
			{
				wallet: "0x5A29e7494Cb71B3FBC2a7a3c9D92234C9C995BA4",
				initialState: 623,
				summary: 623,
				share: "fetch from data 0",
				points: 0,
				origin: 208
			},
			{
				wallet: "0x895e00f3016BCA3D673463ec4bEdE3708a890045",
				initialState: 400,
				summary: 600,
				share: "fetch from data 200",
				points: 200,
				origin: 231
			},
			{
				wallet: "0xcdfa2c5ad7c507ef863cd014b6cdfcaa4af8fa5e",
				initialState: 594,
				summary: 594,
				share: "fetch from data 0",
				points: 0,
				origin: 211
			},
			{
				wallet: "0x928eD03D7b2C59cCf67b0AE9bfadb0EC89bB6582",
				initialState: 485,
				summary: 585,
				share: "fetch from data 100",
				points: 100,
				origin: 221
			},
			{
				wallet: "0xFBfbbc62F7c7cC2151E887e8F07EF4E055DF9c9e",
				initialState: 571,
				summary: 571,
				share: "fetch from data 0",
				points: 0,
				origin: 212
			},
			{
				wallet: "0xaed5a80A107C100df6293F2Bf8273d5C60D1f3B6",
				initialState: 564,
				summary: 564,
				share: "fetch from data 0",
				points: 0,
				origin: 213
			},
			{
				wallet: "0xa73fe17a249c0A8ae702e66Fa58AF2eE8793E75b",
				initialState: 433,
				summary: 538,
				share: "fetch from data 105",
				points: 105,
				origin: 228
			},
			{
				wallet: "0xD8dA39A4c0801fB906AA19eE80fd5Ac8B647A404",
				initialState: 518,
				summary: 518,
				share: "fetch from data 0",
				points: 0,
				origin: 216
			},
			{
				wallet: "0xc8B7B5A14C8e104B323C82f84F0E4F5827059461",
				initialState: 504,
				summary: 504,
				share: "fetch from data 0",
				points: 0,
				origin: 218
			},
			{
				wallet: "0x4377D05E17bF706c09e5451d09E8FF15959F7E20",
				initialState: 252,
				summary: 504,
				share: "fetch from data 252",
				points: 252,
				origin: 252
			},
			{
				wallet: "0xe107898706AAf0691E4035eC547D1bD382fe1a10",
				initialState: 493,
				summary: 493,
				share: "fetch from data 0",
				points: 0,
				origin: 219
			},
			{
				wallet: "0xDc364211600E9398Adcbd1244b1862e47e812509",
				initialState: 32,
				summary: 488,
				share: "fetch from data 456",
				points: 456,
				origin: 295
			},
			{
				wallet: "0x0c99EEAc687B58A30E7e08b39E40ac605a8EeCFf",
				initialState: 395,
				summary: 482,
				share: "fetch from data 87",
				points: 87,
				origin: 235
			},
			{
				wallet: "0xc6547284d2c7dBDc4bc7a29105B01d62c03b9481",
				initialState: 476,
				summary: 476,
				share: "fetch from data 0",
				points: 0,
				origin: 223
			},
			{
				wallet: "0x57427585B908dA592A2094e193554c7B8A7fb955",
				initialState: 472,
				summary: 472,
				share: "fetch from data 0",
				points: 0,
				origin: 224
			},
			{
				wallet: "0xB9BC3901928D7A12753527cc2C918c344C2A4f8C",
				initialState: 445,
				summary: 445,
				share: "fetch from data 0",
				points: 0,
				origin: 226
			},
			{
				wallet: "0xfc066e02904cF6B81397Ce2DfF50ac40AdD6F707",
				initialState: 300,
				summary: 412,
				share: "fetch from data 112",
				points: 112,
				origin: 246
			},
			{
				wallet: "0x969264F48aC058b8652d8d1C4D3316A5c2832606",
				initialState: 232,
				summary: 406,
				share: "fetch from data 174",
				points: 174,
				origin: 256
			},
			{
				wallet: "0xbD70A0F5733270E9C9893E3892065C7E4fa95b84",
				initialState: 401,
				summary: 401,
				share: "fetch from data 0",
				points: 0,
				origin: 229
			},
			{
				wallet: "0x2B601E1BE4a7B098ebf247061d5cFa963c9d2d87",
				initialState: 400,
				summary: 400,
				share: "fetch from data 0",
				points: 0,
				origin: 230
			},
			{
				wallet: "0xd4D992F9509Efe9b3ca1b7aCFaAB384ebB99e0cd",
				initialState: 400,
				summary: 400,
				share: "fetch from data 0",
				points: 0,
				origin: 232
			},
			{
				wallet: "0xE85D8584753b770E83B3d491AC2b9c3cf4A64aaf",
				initialState: 400,
				summary: 400,
				share: "fetch from data 0",
				points: 0,
				origin: 233
			},
			{
				wallet: "0xEB8ca482eE0F547e9e3bE84c36A3aE014e49ea99",
				initialState: 400,
				summary: 400,
				share: "fetch from history with 0",
				points: 0,
				origin: 234
			},
			{
				wallet: "0xb831dD8fB640Ee5aED49568cEF824aFBC2aEE730",
				initialState: 383,
				summary: 383,
				share: "fetch from data 0",
				points: 0,
				origin: 236
			},
			{
				wallet: "0x25C924089f64D1c25F0FA0A52b368d7A4B09aB36",
				initialState: 376,
				summary: 376,
				share: "fetch from data 0",
				points: 0,
				origin: 237
			},
			{
				wallet: "0xc8b269fcAFa4F86156c4C280603c5E23f182fF0a",
				initialState: 373,
				summary: 373,
				share: "fetch from data 0",
				points: 0,
				origin: 238
			},
			{
				wallet: "0xF3D756A3431182eE75e9e41652e21dA76f2d97e0",
				initialState: 363,
				summary: 363,
				share: "fetch from data 0",
				points: 0,
				origin: 239
			},
			{
				wallet: "0x9fE02ed32444a66e30540FCbC90f37B965362aEB",
				initialState: 361,
				summary: 361,
				share: "fetch from data 0",
				points: 0,
				origin: 240
			},
			{
				wallet: "0xcd67218C1B3B09754A1Eccb95F70B7bdaBF80c94",
				initialState: 246,
				summary: 356,
				share: "fetch from data 110",
				points: 110,
				origin: 254
			},
			{
				wallet: "0xB71F7D7F7D71eE6FB50Db8E8a9Bb1A18203F7349",
				initialState: 355,
				summary: 355,
				share: "fetch from data 0",
				points: 0,
				origin: 241
			},
			{
				wallet: "0x0452ea1eFF2c187F7b4a7bBaf2729d1D1578ca52",
				initialState: 350,
				summary: 350,
				share: "fetch from data 0",
				points: 0,
				origin: 242
			},
			{
				wallet: "0xfb47e2e52E6df63e6490A2D3dA89819084a53dB6",
				initialState: 316,
				summary: 316,
				share: "fetch from data 0",
				points: 0,
				origin: 244
			},
			{
				wallet: "0x961114A92B03Bd90060F91E3cD0C24EFd0ed8F56",
				initialState: 298,
				summary: 298,
				share: "fetch from data 0",
				points: 0,
				origin: 247
			},
			{
				wallet: "0x3e0088B6722EC1F892FEBcABB5AF26d88d977d25",
				initialState: 186,
				summary: 287,
				share: "fetch from data 101",
				points: 101,
				origin: 263
			},
			{
				wallet: "0xD05cA3c955Ac37c2b7135a0a03c0eDcC2906dE07",
				initialState: 280,
				summary: 280,
				share: "fetch from data 0",
				points: 0,
				origin: 248
			},
			{
				wallet: "0xD3ccF68fDa8777b628E57fc02E2e54b3Dcb551F5",
				initialState: 271,
				summary: 271,
				share: "fetch from data 0",
				points: 0,
				origin: 249
			},
			{
				wallet: "0x4E1F9adc536b55623954223aAF4e23CDf7CC2d9e",
				initialState: 267,
				summary: 267,
				share: "fetch from data 0",
				points: 0,
				origin: 250
			},
			{
				wallet: "0xaB88f392CB37446DB0ED4f95eD73e70F8e03bC2B",
				initialState: 265,
				summary: 265,
				share: "fetch from data 0",
				points: 0,
				origin: 251
			},
			{
				wallet: "0xbe9cFd199Af2e3203a9740BE0a5840D07B2F6042",
				initialState: 246,
				summary: 246,
				share: "fetch from data 0",
				points: 0,
				origin: 253
			},
			{
				wallet: "0x088E901C8c94111612ae7bdec41872ec328b77E0",
				initialState: 160,
				summary: 240,
				share: "fetch from data 80",
				points: 80,
				origin: 269
			},
			{
				wallet: "0x04C76F25148F89Df961be0e1c6e562365c8Ec432",
				initialState: 234,
				summary: 234,
				share: "fetch from data 0",
				points: 0,
				origin: 255
			},
			{
				wallet: "0xeedc06228b2ea47FD78c58182BfF04CEcB47a9e9",
				initialState: 220,
				summary: 220,
				share: "fetch from data 0",
				points: 0,
				origin: 257
			},
			{
				wallet: "0x360bA5Bf6917e967c2a7BCea18Cc8F8ef6536A07",
				initialState: 200,
				summary: 200,
				share: "fetch from data 0",
				points: 0,
				origin: 258
			},
			{
				wallet: "0x38df5EaB959095F4589686f4D725c7d0a7ba8F75",
				initialState: 200,
				summary: 200,
				share: "fetch from data 0",
				points: 0,
				origin: 259
			},
			{
				wallet: "0x70635102CF3B7D32FE0a1221b412Cb4b172A2b2f",
				initialState: 200,
				summary: 200,
				share: "fetch from data 0",
				points: 0,
				origin: 260
			},
			{
				wallet: "0x7796D7E2F04b2854cB32F52C6014bEe89fD93C18",
				initialState: 200,
				summary: 200,
				share: "fetch from data 0",
				points: 0,
				origin: 261
			},
			{
				wallet: "0xaef4BAA66318f4821402998272678274b6123fcC",
				initialState: 200,
				summary: 200,
				share: "fetch from data 0",
				points: 0,
				origin: 262
			},
			{
				wallet: "0xC01C2B2EbB9B93B2cA7f4a61E9Ac60Ba7750c9E4",
				initialState: 184,
				summary: 184,
				share: "fetch from data 0",
				points: 0,
				origin: 264
			},
			{
				wallet: "0x03884A2013A72C3E3bBF21Fd6756BD6791D153AD",
				initialState: 182,
				summary: 182,
				share: "fetch from data 0",
				points: 0,
				origin: 265
			},
			{
				wallet: "0x0Eac0Ae37774b090EC14C9D4C28499596AD84A11",
				initialState: 181,
				summary: 181,
				share: "fetch from data 0",
				points: 0,
				origin: 266
			},
			{
				wallet: "0x3EC66EbE2E1Bf1e302d048f673933C622A6826fb",
				initialState: 178,
				summary: 178,
				share: "fetch from data 0",
				points: 0,
				origin: 267
			},
			{
				wallet: "0xDEE79D1CA941df0Cb9F34Bc573DcfcF8923eB4E0",
				initialState: 164,
				summary: 164,
				share: "fetch from data 0",
				points: 0,
				origin: 268
			},
			{
				wallet: "0x334A1831219782c3DCdb905f8575bF27d1b76C9D",
				initialState: 157,
				summary: 157,
				share: "fetch from data 0",
				points: 0,
				origin: 272
			},
			{
				wallet: "0xA7556c667B7cf7A1Ab2767CBC2370ca2fb73627e",
				initialState: 152,
				summary: 152,
				share: "fetch from data 0",
				points: 0,
				origin: 273
			},
			{
				wallet: "0x6feAA0A241ad481EA297c7fD42EbA1fb7A694C31",
				initialState: 152,
				summary: 152,
				share: "fetch from data 0",
				points: 0,
				origin: 274
			},
			{
				wallet: "0x9C69B5437E261323bdd5B32f4d15aA3f9F00BC3a",
				initialState: 73,
				summary: 131,
				share: "fetch from data 58",
				points: 58,
				origin: 287
			},
			{
				wallet: "0xC3408d1Ce37326D9A42EF35842a66B6947FCeA38",
				initialState: 125,
				summary: 125,
				share: "fetch from data 0",
				points: 0,
				origin: 275
			},
			{
				wallet: "0x0cFa310e2365FbAF4852cfF85dceC5549DD5cb65",
				initialState: 117,
				summary: 117,
				share: "fetch from data 0",
				points: 0,
				origin: 276
			},
			{
				wallet: "0x2F670Cef2aC736D1015c53207B81d390382e891c",
				initialState: 117,
				summary: 117,
				share: "fetch from data 0",
				points: 0,
				origin: 277
			},
			{
				wallet: "0xf4C46B1c36756a47F50444957b07231335fD1C78",
				initialState: 58,
				summary: 117,
				share: "fetch from data 59",
				points: 59,
				origin: 290
			},
			{
				wallet: "0xC83af323925c55680fdeDD3EF4f766390ac240ae",
				initialState: 100,
				summary: 100,
				share: "fetch from data 0",
				points: 0,
				origin: 280
			},
			{
				wallet: "0xedb4Ec2E401B984264b4bf8B7DD62a9ec11Ee37A",
				initialState: 100,
				summary: 100,
				share: "fetch from data 0",
				points: 0,
				origin: 281
			},
			{
				wallet: "0x223450159B0674DBe2E44FBFCB0Cab056D2517ED",
				initialState: 87,
				summary: 87,
				share: "fetch from data 0",
				points: 0,
				origin: 283
			},
			{
				wallet: "0xFd256b1d16A5CCb442132fab093EF54524513770",
				initialState: 87,
				summary: 87,
				share: "fetch from data 0",
				points: 0,
				origin: 284
			},
			{
				wallet: "0x08DFBccA7bD77D1ebb62107b923D48a07b117742",
				initialState: 82,
				summary: 82,
				share: "fetch from data 0",
				points: 0,
				origin: 285
			},
			{
				wallet: "0x782f888A9EA1b53b341dfca997C0951933997DaA",
				initialState: 79,
				summary: 79,
				share: "fetch from data 0",
				points: 0,
				origin: 286
			},
			{
				wallet: "0x1cC441f5827B69Bb0a4BF61156b7d1EA473B234d",
				initialState: 72,
				summary: 72,
				share: "fetch from data 0",
				points: 0,
				origin: 288
			},
			{
				wallet: "0x71B2A0b2dd37a7B588ECC5352AAf16b1dD650326",
				initialState: 53,
				summary: 53,
				share: "fetch from data 0",
				points: 0,
				origin: 291
			},
			{
				wallet: "0x35732689A360BE37059f82C4dEde0Fa70Eba52AD",
				initialState: 48,
				summary: 48,
				share: "fetch from data 0",
				points: 0,
				origin: 292
			},
			{
				wallet: "0x21f836C7815B001EEcd0c049529c92ff953dFD05",
				initialState: 46,
				summary: 46,
				share: "fetch from data 0",
				points: 0,
				origin: 293
			},
			{
				wallet: "0xcE7d2f5526F89f89c0927b2e0EF7633581435e53",
				initialState: 40,
				summary: 40,
				share: "fetch from data 0",
				points: 0,
				origin: 294
			},
			{
				wallet: "0x22d14BF9fa7a36F514CBC7b21EE36623983938d4",
				initialState: 31,
				summary: 31,
				share: "fetch from data 0",
				points: 0,
				origin: 296
			},
			{
				wallet: "0x81Fb8E022c6e402eBB7d342ACC94c2c7969bC0b1",
				initialState: 27,
				summary: 27,
				share: "fetch from data 0",
				points: 0,
				origin: 297
			},
			{
				wallet: "0x44805366130d747A062f1dB7119324229d1F5A01",
				initialState: 23,
				summary: 23,
				share: "fetch from data 0",
				points: 0,
				origin: 299
			},
			{
				wallet: "0xf093210DA74a3FCc6aA5CF611C43429Fc812BBba",
				initialState: 23,
				summary: 23,
				share: "fetch from data 0",
				points: 0,
				origin: 300
			},
			{
				wallet: "0xC4C77D94661DBBde746aAf585c3e8f3109f8b6a0",
				initialState: 21,
				summary: 21,
				share: "fetch from data 0",
				points: 0,
				origin: 302
			},
			{
				wallet: "0x4D4b5acE61414494dbcC4d2c3484D2bf92658A01",
				initialState: 16,
				summary: 16,
				share: "fetch from data 0",
				points: 0,
				origin: 303
			},
			{
				wallet: "0x47f782f4c932b4052f7BFa34eBF9B32883e6b921",
				initialState: 15,
				summary: 15,
				share: "fetch from data 0",
				points: 0,
				origin: 304
			}
		]
	}
};
