import * as React from "react";
import * as Sentry from "@sentry/react";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ReactComponent as QuestionIcon } from "./../src/icons/question.svg";
import { useEffect, useState } from "react";
import {
	Header,
	StatsContainer,
	StatsTableWrapper,
	TableWrapper,
	TooltipElement
} from "./App.styles";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { TableContainer, Typography } from "@mui/material";
import { addToSentry } from "./sentryUtils";
import FeedbackModal from "./FeedbackModal";

const StatsTables = ({ stats, name }: any) => {
	const [tableData, setTableData] = useState<any[]>([]);
	const [openTooltip, setOpenTooltip] = useState(false);
	const handleOpenTooltip = () => {
		setOpenTooltip(true);
	};

	useEffect(() => {
		try {
			if (stats?.previous?.table && stats?.new?.table) {
				const oldTable = stats?.previous?.table || [];
				const newTable = stats?.new?.table || [];

				let rankList: any[] = [];
				oldTable.map((obj: any, oldIndex: any) => {
					const newIndex = newTable.findIndex(
						(e: any) => obj.wallet === e.wallet
					);

					if (
						newTable[newIndex]?.share?.includes("history") ||
						obj?.share?.includes("history")
					) {
						addToSentry(
							`fetched from history ${obj.wallet} in ${name}`,
							"debug"
						);
					}

					if (newTable[newIndex]?.points === -1 || obj.points === -1) {
						addToSentry(
							`incorrect data from wallet ${obj.wallet} in ${name}`,
							"error"
						);
						console.log("error: incorrect data from wallet " + obj.wallet);
					} else {
						if (newTable[newIndex]?.points < obj.points) {
							addToSentry(
								`new points are smaller than old one : incorrect data from wallet ${obj.wallet} in ${name}`,
								"error"
							);
							console.log("error: incorrect data from wallet " + obj.wallet);
							rankList.push({ wallet: obj.wallet, change: 0 });
						} else {
							const change = oldIndex - newIndex;

							rankList.push({ wallet: obj.wallet, change });
						}
					}
				});
				rankList = rankList.sort((a, b) => b.change - a.change);

				setTableData(
					rankList
						.slice(0, 5)
						.concat(rankList.slice(rankList.length - 5, rankList.length))
				);
			}
		} catch (e) {}
	}, [stats]);
	const content =
		name === "Hourly"
			? `We collect hourly statistics every hour. In the table, we present the position change between the new hourly report and the previous one.`
			: `We collect daily statistics every day at 00:00 UTC. In the table, we display the position change between the new daily report and the previous one.`;

	return (
		<StatsTableWrapper>
			<TooltipElement onClick={handleOpenTooltip}>
				<QuestionIcon />
			</TooltipElement>
			<TableContainer component={Paper}>
				<Table
					sx={{ minWidth: 500, maxWidth: 800 }}
					size="small"
					aria-label="a dense table"
				>
					<TableHead>
						<TableCell>
							{" "}
							<Typography
								textTransform="uppercase"
								color={`rgb(21, 253, 234)`}
								fontWeight="400"
								fontFamily="Dosis"
							>
								Wallet Address
							</Typography>
						</TableCell>
						<TableCell>
							{" "}
							<Typography
								textTransform="uppercase"
								color={`rgb(21, 253, 234)`}
								fontWeight="400"
								fontFamily="Dosis"
							>
								{name} Position Change
							</Typography>
						</TableCell>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell colSpan={1} scope="row">
								<div>
									<Typography
										textTransform="uppercase"
										color={`rgb(21, 253, 234)`}
										fontWeight="400"
										fontFamily="Dosis"
									>
										Biggest Gainers
									</Typography>
								</div>
							</TableCell>
							<TableCell colSpan={1} scope="row">
								<div>
									{" "}
									<Typography
										textTransform="uppercase"
										color={`rgb(21, 253, 234)`}
										fontWeight="400"
										fontFamily="Dosis"
									></Typography>
								</div>
							</TableCell>
						</TableRow>
						{tableData.slice(0, 5).map((elem, index) => (
							<TableRow>
								<TableCell>
									<div>
										<Typography textTransform="uppercase" fontSize="0.8rem">
											{elem.wallet}
										</Typography>
									</div>
								</TableCell>
								<TableCell>
									<div>
										{elem.change === 0 ? (
											<Typography
												textTransform="uppercase"
												fontSize="0.8rem"
												fontFamily="Nobile-regular"
											>
												{elem.change}
											</Typography>
										) : (
											<>
												<Typography
													textTransform="uppercase"
													fontSize="0.8rem"
													fontFamily="Nobile-regular"
												>
													+{elem.change}{" "}
												</Typography>
												<ArrowUpwardIcon
													color="success"
													style={{ color: "rgb(21, 253, 234)" }}
												/>
											</>
										)}
									</div>
								</TableCell>
							</TableRow>
						))}
						<TableRow>
							<TableCell colSpan={1} scope="row">
								<div>
									<Typography
										textTransform="uppercase"
										color={`rgb(248, 6, 242)`}
										fontWeight="400"
										fontFamily="Dosis"
									>
										Biggest Losers
									</Typography>
								</div>
							</TableCell>
							<TableCell colSpan={1} scope="row">
								<div>
									<Typography
										textTransform="uppercase"
										color={`rgb(21, 253, 234)`}
										fontWeight="400"
										fontFamily="Dosis"
									></Typography>
								</div>
							</TableCell>
						</TableRow>
						{tableData
							.slice(5, 10)
							.reverse()
							.map((elem, index) => (
								<TableRow>
									<TableCell>
										<div>
											<Typography textTransform="uppercase" fontSize="0.8rem">
												{elem.wallet}
											</Typography>
										</div>
									</TableCell>
									<TableCell>
										<div>
											{elem.change === 0 ? (
												<Typography
													textTransform="uppercase"
													fontSize="0.8rem"
													fontFamily="Nobile-regular"
												>
													{elem.change}
												</Typography>
											) : (
												<>
													<Typography
														textTransform="uppercase"
														fontSize="0.8rem"
														fontFamily="Nobile-regular"
													>
														{elem.change}{" "}
													</Typography>
													<ArrowDownwardIcon
														color="error"
														style={{ color: "rgb(248, 6, 242)" }}
													/>
												</>
											)}
										</div>
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
			<FeedbackModal
				open={openTooltip}
				setOpen={setOpenTooltip}
				content={
					<>
						<Typography
							textTransform="uppercase"
							fontFamily="Dosis"
							align="center"
							color="white"
							component="h3"
						>
							{name} statistics
						</Typography>
						<Typography fontFamily="Dosis" align="center" color="white">
							{content}
						</Typography>
					</>
				}
			></FeedbackModal>
		</StatsTableWrapper>
	);
};

export default StatsTables;
