import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { TableContainer, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { useEffect, useState } from "react";
import { RankTableWrapper } from "./App.styles";
import { addToSentry } from "./sentryUtils";

const RankTable = ({ stats, name }: any) => {
	const [tableData, setTableData] = useState<any[]>([]);

	useEffect(() => {
		if (stats?.previous?.table && stats?.new?.table) {
			const oldTable = stats?.previous?.table || [];
			const newTable = stats?.new?.table || [];

			let rankList: any[] = [];
			oldTable.map((obj: any, oldIndex: any) => {
				const newIndex = newTable.findIndex(
					(e: any) => obj.wallet === e.wallet
				);

				if (newTable[newIndex]?.points === -1 || obj.points === -1) {
				} else {
					if (newTable[newIndex]?.points < obj.points) {
						rankList.push({ wallet: obj.wallet, change: 0 });
					} else {
						const change = newTable[newIndex]?.points - obj.points;

						rankList.push({ wallet: obj.wallet, change });
					}
				}
			});
			rankList = rankList.sort((a, b) => b.change - a.change);

			setTableData(
				rankList
					.slice(0, 5)
					.concat(rankList.slice(rankList.length - 5, rankList.length))
			);
		}
	}, [stats]);

	return (
		<RankTableWrapper>
			<TableContainer component={Paper}>
				<Table
					sx={{ minWidth: 500, maxWidth: 800 }}
					size="small"
					aria-label="a dense table"
				>
					<TableHead>
						<TableCell>
							{" "}
							<Typography
								textTransform="uppercase"
								color={`rgb(21, 253, 234)`}
								fontWeight="400"
								fontFamily="Dosis"
							>
								Wallet Address
							</Typography>
						</TableCell>
						<TableCell>
							{" "}
							<Typography
								textTransform="uppercase"
								color={`rgb(21, 253, 234)`}
								fontWeight="400"
								fontFamily="Dosis"
							>
								{name} Points Increment
							</Typography>
						</TableCell>
					</TableHead>
					<TableBody>
						{tableData.slice(0, 5).map((elem, index) => (
							<TableRow key={`${elem.wallet}-rank-${name}`}>
								<TableCell>
									<div>
										<Typography textTransform="uppercase" fontSize="0.8rem">
											{elem.wallet}
										</Typography>
									</div>
								</TableCell>
								<TableCell>
									<div>
										{elem.change === 0 ? (
											<Typography
												textTransform="uppercase"
												fontSize="0.8rem"
												fontFamily="Nobile-regular"
											>
												{elem.change}
											</Typography>
										) : (
											<>
												<Typography
													textTransform="uppercase"
													fontSize="0.8rem"
													fontFamily="Nobile-regular"
												>
													+{elem.change}{" "}
												</Typography>
												<ArrowUpwardIcon
													color="success"
													style={{ color: "rgb(21, 253, 234)" }}
												/>
											</>
										)}
									</div>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</RankTableWrapper>
	);
};

export default RankTable;
