import React, { useState } from "react";

import TableRow from "@mui/material/TableRow";
import RefreshIcon from "@mui/icons-material/Refresh";
import { CircularProgress, TableContainer, Typography } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { API_TOKEN } from "./App";
import { IconWrapper } from "./App.styles";
const CONTRACT_ADDRESS = "0x680F403D7F127Db6186a76BeFc3cfe4A2442fA0c";
const getUrl = (wallet: string, key: string) => {
	return `https://api.polygonscan.com/api?module=account&action=tokenbalance&contractaddress=${CONTRACT_ADDRESS}&address=${wallet}&tag=latest&apikey=${key}`;
};

const RowComponent = ({
	row,
	index,
	totalPoints,
	tableData,
	setTableData
}: any) => {
	const [clicked, setClicked] = useState(false);

	return (
		<TableRow key={`${index}-${row.wallet}-${Math.random()}`}>
			<TableCell>
				<div>
					<Typography
						textTransform="uppercase"
						color={`rgb(21, 253, 234)`}
						fontWeight="700"
						fontFamily="Dosis"
						fontSize="1.5rem"
					>
						{index}
					</Typography>
				</div>
			</TableCell>
			<TableCell>
				<div>
					<Typography textTransform="uppercase" fontSize="0.8rem">
						{row.wallet}
					</Typography>
				</div>
			</TableCell>
			<TableCell>
				<div>
					<Typography
						textTransform="uppercase"
						fontSize="0.8rem"
						fontFamily="Nobile-regular"
					>
						{row.summary}
					</Typography>
				</div>
			</TableCell>
			<TableCell>
				<div>
					{" "}
					<Typography
						textTransform="uppercase"
						fontSize="0.8rem"
						fontFamily="Nobile-regular"
					>
						{((row.summary / totalPoints) * 100).toFixed(2)}
					</Typography>
				</div>
			</TableCell>
			<TableCell>
				<div>
					<Typography
						textTransform="uppercase"
						fontSize="0.8rem"
						fontFamily="Nobile-regular"
					>
						{row.points === -1 ? "No data" : row.points}
					</Typography>
				</div>
			</TableCell>
		</TableRow>
	);
};

export default RowComponent;
