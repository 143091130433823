import { useState } from "react";

import { IconButton, Modal, TextField, Typography } from "@mui/material";
import {
	BoxColumn,
	ContactUsButton,
	CustomBox,
	InputWrapper,
	InputWrapperEmail,
	SendWrapper,
	Textarea
} from "./App.styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { ReactComponent as SendIcon } from "./../src/icons/send.svg";
import { addToSentry } from "./sentryUtils";

const ContactUsForm = ({ setOpen, open }: any) => {
	const [email, setEmail] = useState("");
	const [text, setText] = useState("");
	const sendFeedback = () => {
		addToSentry(`Feedback: ${email}, content: ${text}`, "info");
		setEmail("");
		setText("");
		setOpen(false);
	};
	const handleClose = () => {
		setEmail("");
		setText("");
		setOpen(false);
	};

	const handleClearClick = () => {
		setEmail("");
	};

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<CustomBox>
				<BoxColumn>
					<InputWrapperEmail>
						<TextField
							id="outlined-controlled"
							placeholder="E-mail"
							value={email}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								setEmail(event.target.value);
							}}
							InputProps={{
								endAdornment: (
									<IconButton
										sx={{ visibility: email ? "visible" : "hidden" }}
										onClick={handleClearClick}
									>
										<DeleteIcon />
									</IconButton>
								)
							}}
						/>
					</InputWrapperEmail>
					<Textarea
						maxRows={20}
						minRows={20}
						placeholder="Text"
						defaultValue={text}
						onChange={event => {
							setText(event.target.value);
						}}
					/>
				</BoxColumn>
				<SendWrapper>
					<ContactUsButton
						variant="contained"
						endIcon={<SendIcon />}
						onClick={sendFeedback}
					>
						<Typography
							textTransform="uppercase"
							color={`rgb(0,1,34)`}
							fontWeight="700"
							fontFamily="Dosis"
							align="center"
						>
							Send
						</Typography>
					</ContactUsButton>
				</SendWrapper>
			</CustomBox>
		</Modal>
	);
};

export default ContactUsForm;
