import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

import Paper from "@mui/material/Paper";

import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import TableHead from "@mui/material/TableHead";
import RowComponent from "./Row";
import {
	Empty,
	TableWrapper,
	SummaryRow,
	SaveButton,
	DeleteButton
} from "./App.styles";
import IconButton from "@mui/material/IconButton";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";

interface TablePaginationActionsProps {
	count: number;
	page: number;
	rowsPerPage: number;
	onPageChange: (
		event: React.MouseEvent<HTMLButtonElement>,
		newPage: number
	) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowRight />
				) : (
					<KeyboardArrowLeft />
				)}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowLeft />
				) : (
					<KeyboardArrowRight />
				)}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

const TableComponent = ({
	tableData,
	totalPoints,
	sumOfTaskPoints,
	setTableData
}: any) => {
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const [sort, setSort] = React.useState("summary");

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;

	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<TableWrapper>
			<div>
				<SaveButton variant="contained" onClick={() => setSort("points")}>
					<Typography
						textTransform="uppercase"
						color={`rgb(0,1,34)`}
						fontWeight="700"
						fontFamily="Dosis"
						align="center"
					>
						Sort by SP
					</Typography>
				</SaveButton>
				<DeleteButton
					variant="outlined"
					onClick={() => setSort("summary")}
					disabled={sort === "summary"}
				>
					<Typography
						textTransform="uppercase"
						color={`rgb(21, 253, 234)`}
						fontWeight="700"
						fontFamily="Dosis"
						align="center"
					>
						Reset sorting
					</Typography>
				</DeleteButton>
			</div>
			<TableContainer component={Paper}>
				<Table
					sx={{ minWidth: 500, maxWidth: 800 }}
					aria-label="custom pagination table"
				>
					<TableHead>
						<TableRow>
							<TableCell>
								<Typography
									textTransform="uppercase"
									color={`rgb(21, 253, 234)`}
									fontWeight="400"
									fontFamily="Dosis"
									align="center"
								>
									Current Position
								</Typography>
							</TableCell>
							<TableCell>
								<Typography
									textTransform="uppercase"
									color={`rgb(21, 253, 234)`}
									fontWeight="400"
									fontFamily="Dosis"
									align="center"
								>
									Wallet Address
								</Typography>
							</TableCell>
							<TableCell>
								<Typography
									textTransform="uppercase"
									color={`rgb(21, 253, 234)`}
									fontWeight="400"
									fontFamily="Dosis"
									align="center"
								>
									Total Current Points
								</Typography>
							</TableCell>
							<TableCell>
								<Typography
									textTransform="uppercase"
									color={`rgb(21, 253, 234)`}
									fontWeight="400"
									fontFamily="Dosis"
									align="center"
								>
									Share [%]
								</Typography>
							</TableCell>
							<TableCell>
								<Typography
									textTransform="uppercase"
									color={`rgb(21, 253, 234)`}
									fontWeight="400"
									fontFamily="Dosis"
									align="center"
								>
									SP (Survivor Points)
								</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(rowsPerPage > 0
							? [
									...tableData.sort((a: any, b: any) => {
										const elema = a[sort];
										const elemb = b[sort];

										return Number(elemb) - Number(elema);
									})
							  ].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							: [
									...tableData.sort((a: any, b: any) => {
										const elema = a[sort];
										const elemb = b[sort];

										return Number(elemb) - Number(elema);
									})
							  ]
						).map(
							(
								row: {
									wallet: string;
									initialState: number;
									share: number | null;
									points: number | null;
									origin: number;
									summary: number;
								},
								index: number
							) => (
								<RowComponent
									key={row.wallet}
									row={row}
									index={index + 1 + page * rowsPerPage}
									totalPoints={totalPoints}
									tableData={tableData}
									setTableData={setTableData}
								></RowComponent>
							)
						)}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={8} />
							</TableRow>
						)}
						<TableRow>
							<TableCell colSpan={8} component="td" scope="row">
								<SummaryRow>
									<Typography fontSize="0.8rem" fontFamily="Nobile-regular">
										Total distribution of SP (Survivor Points):{" "}
										{sumOfTaskPoints}
									</Typography>
								</SummaryRow>
							</TableCell>
						</TableRow>
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
								rowsPerPageOptions={[
									10,
									20,
									50,
									100,
									200,
									{ label: "All", value: -1 }
								]}
								colSpan={8}
								count={tableData.length}
								rowsPerPage={rowsPerPage}
								page={page}
								SelectProps={{
									inputProps: {
										"aria-label": "rows per page"
									},
									native: true
								}}
								sx={{
									"& .MuiTablePagination-spacer": {
										order: 2,
										fontFamily: "Nobile-regular"
									},
									"& .MuiTablePagination-selectLabel": {
										order: 0,
										fontFamily: "Nobile-regular"
									},
									"& .MuiTablePagination-select": {
										order: 1,
										fontFamily: "Nobile-regular"
									},
									"& .MuiTablePagination-displayedRows": {
										order: 3,
										fontFamily: "Nobile-regular"
									},
									"& .MuiTablePagination-actions": {
										order: 4,
										fontFamily: "Nobile-regular"
									},
									"& .MuiIconButton-root": {
										color: "white"
									},
									"& .MuiNativeSelect-icon": {
										color: "white"
									},
									"& .MuiTablePagination-menuItem": {
										color: "black"
									}
								}}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								ActionsComponent={TablePaginationActions}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</TableWrapper>
	);
};
export default TableComponent;
