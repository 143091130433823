import { useEffect, useState } from "react";

import { Typography } from "@mui/material";
import { ReactComponent as ContactIcon } from "./../src/icons/contact.svg";
import { ReactComponent as QuestionIcon } from "./../src/icons/question.svg";
import {
	ButtonSendWrapper,
	ContactUsButton,
	GainersHeader,
	Header,
	HeaderPointsGainers,
	MainWrapper,
	TableWrapper,
	TabsWrapper,
	TabsWrapperRank,
	TabsWrapperRanks,
	TooltipElement
} from "./App.styles";
import PrizePoolTable from "./PrizePoolTable";
import TableComponent from "./TableComponent";
import { data } from "./dataArray";
import { links } from "./urls";
import { weekly } from "./weekly";
import { daily } from "./daily";
import { hourly } from "./hourly";
import ContactUsForm from "./ContactUsForm";
import RankTable from "./RankTable";
import StatsTables from "./StatsTables";
import YourWallet from "./YourWallet";
import { addToSentry } from "./sentryUtils";
import FeedbackModal from "./FeedbackModal";

function delay(time: any) {
	return new Promise(resolve => setTimeout(resolve, time));
}
export const API_TOKEN = "62SGDI8CISAT5MIEJTER85TJUVRPPD56T6";
const App = () => {
	const [tableData, setTableData] = useState<any>(
		hourly?.new?.table.filter((elem: any) => elem.points > 0) || []
	);
	const [hourlyStats, setHourlyStats] = useState<any>([]);
	const [dailyStats, setDailyStats] = useState<any>([]);
	const [weeklyStats, setWeeklyStats] = useState<any>([]);

	const [startLoad, setStartLoad] = useState(false);
	const [isSentryEnable, setIsSentryEnable] = useState(false);

	const [failed, setFailed] = useState<string[]>([]);
	const [runRetryLoop, setRunRetryLoop] = useState(false);
	const [totalPoints, setTotalPoints] = useState(0);
	const [sumOfTaskPoints, setSumOfTaskPoints] = useState(0);

	const [nextLoopIntervalId, setNextLoopIntervalId] = useState<any>(null);

	const [open, setOpen] = useState(false);
	const handleOpen = () => {
		setOpen(true);
	};

	const [openTooltip, setOpenTooltip] = useState(false);
	const handleOpenTooltip = () => {
		setOpenTooltip(true);
	};

	const NUMBER_OF_FETCH_ONCE = 10;

	// const fetchAllData = async () => {
	// 	for (
	// 		let counter = 0;
	// 		counter < tableData.length;
	// 		counter = counter + NUMBER_OF_FETCH_ONCE
	// 	) {
	// 		links
	// 			.slice(counter, counter + NUMBER_OF_FETCH_ONCE)
	// 			.map(async (url, index) => {
	// 				const newIndex = counter + index;

	// 				try {
	// 					const res = await fetch(url);
	// 					const result = await res.json();

	// 					const nexIndex = tableData.findIndex((elem: any) => {
	// 						return elem.origin === index + counter;
	// 					});

	// 					if (result.status === "1") {
	// 						const newData = tableData;

	// 						const points =
	// 							Number(result.result) !== 0
	// 								? Number(result.result) / 1000000000000000000
	// 								: 0;
	// 						newData[nexIndex].points = Math.round(points);

	// 						newData[nexIndex].summary = Math.round(
	// 							Math.round(points === -1 ? 0 : points) +
	// 								Number(newData[nexIndex].initialState)
	// 						);

	// 						setTableData([...newData]);
	// 					} else {
	// 						setFailed(oldArray => [...oldArray, tableData[nexIndex]?.wallet]);
	// 					}
	// 				} catch (error) {
	// 					const nexIndex = tableData.findIndex(
	// 						(elem: any) => elem.origin === index + counter
	// 					);

	// 					setFailed(oldArray => [...oldArray, tableData[nexIndex]?.wallet]);
	// 				}
	// 			});
	// 		await delay(1100);
	// 	}
	// 	setRunRetryLoop(true);
	// };

	useEffect(() => {
		const loadData = async () => {
			try {
				addToSentry(`data loaded`, "info");

				// const response = await fetch(
				// 	"https://o4506138993557504.ingest.sentry.io/api/4506155297144832/envelope/?sentry_key=cf848c3671eadfb39109235dff212417&sentry_version=7&sentry_client=sentry.javascript.react%2F7.77.0",
				// 	{
				// 		headers: {
				// 			"content-type": "text/plain;charset=UTF-8",
				// 			"sec-ch-ua":
				// 				'"Google Chrome";v="119", "Chromium";v="119", "Not?A_Brand";v="24"',
				// 			"sec-ch-ua-mobile": "?0",
				// 			"sec-ch-ua-platform": '"Windows"'
				// 		}
				// 	}
				// );
				setIsSentryEnable(true);
			} catch (e) {
				setIsSentryEnable(false);
			}
			try {
				setHourlyStats(hourly);
			} catch (e) {
				addToSentry(`fail to fetch hourly`, "error");
				console.log(e);
			} finally {
				setStartLoad(true);
			}

			try {
				setDailyStats(daily);
			} catch (e) {
				addToSentry(`fail to fetch daily`, "error");
				console.log(e);
			}

			try {
				setWeeklyStats(weekly);
			} catch (e) {
				addToSentry(`fail to fetch weekly`, "error");
				console.log(e);
			}
		};
		loadData();

		// const secondLoopInterval = setInterval(async () => {
		// 	console.log("refetch");
		// 	setRunRetryLoop(false);
		// 	await fetchAllData();
		// }, 250000);
		// setNextLoopIntervalId(secondLoopInterval);

		// return () => {
		// 	clearInterval(nextLoopIntervalId);
		// };
	}, []);

	// useEffect(() => {
	// 	if (runRetryLoop) {
	// 		console.log(failed);
	// 		failed.map(elem => {
	// 			const newData = tableData;

	// 			const backup = hourlyStats?.new?.table.find(
	// 				(e: any) => e.wallet === elem
	// 			);

	// 			if (backup) {
	// 				const nexIndex = tableData.findIndex((e: any) => e.wallet === elem);
	// 				if (newData[nexIndex]) {
	// 					newData[nexIndex].points = Math.round(backup?.points);

	// 					newData[nexIndex].summary = Math.round(
	// 						Math.round(backup?.points === -1 ? 0 : backup?.points) +
	// 							Number(newData[nexIndex].initialState)
	// 					);

	// 					setTableData([...newData]);
	// 				}
	// 			} else {
	// 				addToSentry(`no backup for ${elem}`, "error");
	// 			}
	// 		});
	// 	}
	// }, [runRetryLoop]);

	useEffect(() => {
		//	fetchAllData();
	}, []);

	useEffect(() => {
		const sum = tableData.reduce((accumulator: any, object: any) => {
			return accumulator + object.summary;
		}, 0);
		setTotalPoints(sum);
		const taskPoints = tableData.reduce((accumulator: any, object: any) => {
			const points = Number(object.points !== -1 ? object.points : 0);
			return accumulator + points;
		}, 0);
		setTotalPoints(sum);
		setSumOfTaskPoints(taskPoints);
	}, [tableData]);

	// useEffect(() => {
	// 	if (startLoad) {
	// 		if (hourlyStats?.new?.table?.length > 0) {
	// 			hourlyStats?.new?.table?.forEach((elem: any) => {
	// 				console.log(elem);
	// 				const origin = tableData.findIndex(
	// 					(e: any) => e.wallet === elem.wallet
	// 				);

	// 				const newData = tableData;
	// 				newData[origin].points = Math.round(elem?.points);
	// 				newData[origin].summary = Math.round(
	// 					Math.round(elem?.initialState) +
	// 						Number(newData[origin].points === -1 ? 0 : newData[origin].points)
	// 				);
	// 				setTableData([...newData]);
	// 			});
	// 		}
	// 		setStartLoad(false);
	// 	}
	// }, [startLoad]);

	return (
		<MainWrapper>
			<Header>
				<Typography
					variant="h3"
					component="h2"
					color="white"
					fontWeight="700"
					fontFamily="Dosis"
				>
					LUNI Survivors Leaderboard - Final Score
				</Typography>
			</Header>

			<PrizePoolTable totalPoints={sumOfTaskPoints}></PrizePoolTable>
			<YourWallet tableData={tableData} totalPoints={totalPoints} />
			<TableWrapper>
				{" "}
				<TableComponent
					tableData={tableData}
					totalPoints={totalPoints}
					sumOfTaskPoints={sumOfTaskPoints}
					setTableData={setTableData}
				></TableComponent>
			</TableWrapper>

			<TabsWrapper>
				<StatsTables stats={hourlyStats} name="Hourly"></StatsTables>

				<StatsTables stats={dailyStats} name="Daily"></StatsTables>
			</TabsWrapper>

			<TabsWrapperRank>
				<TooltipElement onClick={handleOpenTooltip}>
					<QuestionIcon />
				</TooltipElement>
				<HeaderPointsGainers>
					<Typography
						variant="h5"
						component="h5"
						color={`rgb(21, 253, 234)`}
						fontWeight="700"
						fontFamily="Dosis"
						textAlign={"center"}
					>
						RAW SP GAINERS
					</Typography>
				</HeaderPointsGainers>

				<TabsWrapperRanks>
					<RankTable stats={weeklyStats} name="Weekly"></RankTable>

					<RankTable stats={dailyStats} name="Daily"></RankTable>
				</TabsWrapperRanks>
			</TabsWrapperRank>
			<ButtonSendWrapper isSentryEnable={isSentryEnable}>
				<ContactUsButton
					variant="contained"
					disabled={!isSentryEnable}
					endIcon={<ContactIcon />}
					onClick={handleOpen}
				>
					<Typography
						textTransform="uppercase"
						color={`rgb(0,1,34)`}
						fontWeight="700"
						fontFamily="Dosis"
						align="center"
					>
						Contact us
					</Typography>
				</ContactUsButton>
				{!isSentryEnable && (
					<Typography
						color={`white`}
						fontWeight="700"
						fontFamily="Dosis"
						align="center"
					>
						disable adblock to use contact form
					</Typography>
				)}
			</ButtonSendWrapper>

			<ContactUsForm open={open} setOpen={setOpen}></ContactUsForm>
			<FeedbackModal
				open={openTooltip}
				setOpen={setOpenTooltip}
				content={
					<>
						<Typography
							textTransform="uppercase"
							fontFamily="Dosis"
							align="center"
							color="white"
							component="h3"
						>
							RAW SP GAINERS
						</Typography>
						<Typography fontFamily="Dosis" align="center" color="white">
							Weekly statistics are collected every Monday at 00:00 UTC. Daily
							statistics are collected at 00:00 UTC each day. The table displays
							the number of points collected between specific time periods.
						</Typography>
					</>
				}
			></FeedbackModal>
		</MainWrapper>
	);
};

export default App;
