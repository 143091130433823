import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import * as Sentry from "@sentry/react";

// Sentry.init({
// 	dsn: "https://8c0c270e395a6d8c3714c7cb8e35f295@o4506298896875520.ingest.sentry.io/4506298898382848",
// 	integrations: [
// 		new Sentry.BrowserTracing({
// 			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
// 			// tracePropagationTargets: [
// 			// 	"localhost",
// 			// 	"https://test-sentry.d2chqz7h9ezg7o.amplifyapp.com"
// 			// ]
// 		}),
// 		new Sentry.Replay()
// 	],
// 	// Performance Monitoring
// 	tracesSampleRate: 1.0, // Capture 100% of the transactions
// 	// Session Replay
// 	enabled: true,
// 	maxValueLength: 10000,
// 	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
// 	replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	// <React.StrictMode>
	<App />
	// </React.StrictMode>
);
